.emptyinvestment-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.emptyinvestment-box {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.emptyinvestment--text {
    font: $font-tiny-semi;
    color: #C4C4C4;
    text-transform: capitalize;
}