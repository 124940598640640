.homepage {
  .section-record {
    margin-top: 12rem;

    &--img {
      width: 8rem;
      height: 8rem;

      @include responsive(phone-port) {
        width: 6rem;
      }
    }

    &--amount {
      font: $font-big;
    }
  }

  .section-record__opp {
    background: linear-gradient(
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 0.8)
      ),
      url("../../icons/opportunity-small.svg");
    background-size: 95%;
    background-position: -54px 0px;
    background-repeat: no-repeat;
  }

  .section-record__job {
    background: linear-gradient(
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 0.8)
      ),
      url("../../icons/job.svg");
    background-size: 100%;
    background-position: -54px 0px;
    background-repeat: no-repeat;
  }

  .section-record__sub {
    background: linear-gradient(
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 0.8)
      ),
      url("../../icons/sub.svg");
    background-size: 75%;
    background-position: -54px 0px;
    background-repeat: no-repeat;
  }

  .section-record__dis {
    background: linear-gradient(
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 0.8)
      ),
      url("../../icons/distributed.svg");
    background-size: 90%;
    background-position: -54px 0px;
    background-repeat: no-repeat;
  }

  .section-record__card {
    flex: 0 0 20%;

    @include responsive(tab-land) {
      flex: 1 0 40%;

      &:nth-child(2) {
        margin-right: 0;
      }
    }

    @include responsive(phone-port) {
      flex: 1 0 100%;
      margin-right: 0 !important;
    }
  }

  .section-record__card-2 {
    flex: 0 0 28%;

    @include responsive(tab-land) {
      flex: 1 0 40%;

      &:nth-child(2) {
        margin-right: 0;
      }
    }

    @include responsive(phone-port) {
      flex: 1 0 100%;
      margin-right: 0 !important;
    }
  }

  .section-record__col {
    justify-content: center;
  }

  .section-record-content {
    padding: 2rem;

    p {
      margin: 3rem auto;
      font: $font-small;
      max-width: 15.7rem;
      height: 5rem;

      @include responsive(tab-land) {
        height: 2rem;
      }
    }
  }

  .record-header {
    margin-left: 7rem;
    margin-bottom: 5rem;

    @include responsive(phone-port) {
      margin-left: 0;
    }
  }

  .section-risk {
    margin-top: 12rem;
  }

  .section-invest {
    // background-color: #FFEFE5;
    padding: 5rem 7rem 6rem 7rem;

    @include responsive(phone-port) {
      padding-left: 0;
      padding-right: 0;
    }

    &__box {
      width: 100%;
      height: 50rem;
      position: relative;
      // margin-left: 10rem;

      @include responsive(tab-land) {
        margin-left: 0;
      }

      @include responsive(phone-port) {
        //  min-width: 40%;
        height: auto;
      }
    }

    &--img {
      min-width: 100%;
      border-radius: 3px;

      @include responsive(phone-port) {
        //  min-width: 40%;
        height: 200px;
      }
      //  height: 50%;

      // @include responsive(tab-port) {
      //   max-width: 20rem;
      // }
    }

    &--text {
      position: absolute;
      // margin-left: 15rem;
      font: $font-small;
      font-size: 1.3rem;
      top: 250px;
      left: 50%;
      height: 10rem;
      text-align: center;
      transform: translate(-50%, -50%);
      color: #fff;
      // max-width: 30rem;
      align-self: center;
      z-index: 501;

      @include responsive(tab-port) {
        margin-left: 5rem;
      }

      @include responsive(phone-port) {
        top: 70px;
        margin-left: 0;
        margin-top: 2rem;
        // max-width: 100%;
      }

      h1 {
        font: $font-medium-bold;
        font-size: 3rem;
        text-transform: capitalize;
        margin-bottom: 1rem;

        @include responsive(phone-port) {
          font-size: 2rem;
          line-height: 1.8;
          // max-width: 100%;
        }
      }
    }
  }

  .section-invest__grid {
    width: 100%;
    position: relative;
    // @include responsive(phone-port) {
    //   flex-direction: column;
    //   align-items: center;
    //   justify-content: center;
    //   text-align: center;
    // }

    &:after {
      content: "";
      display: block;
      position: absolute;
      background-image: linear-gradient(
        to right,
        rgba(0, 31, 58, 0.8),
        rgba(0, 31, 58, 0.8)
      );
      // margin-top: -740px;
      top: 0%;
      height: 50rem;
      width: 100%;
      z-index: 500;

      @include responsive(phone-port) {
        height: 200px;
        // max-width: 100%;
      }
    }
  }

  .slider-control-bottomcenter {
    margin-top: 3rem;
    @include responsive(phone-port) {
      justify-content: center;
      width: 100%;
      // align-items: center;
    }
  }

  .section-services {
    &__icon {
      width: 100px;
    }

    &__box {
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      padding: 0 10rem;
      height: 20rem;
      border-radius: 5px;

      @include responsive(tab-land) {
        flex: 1 0 20%;
        height: 25rem;
        border-radius: 10px;
      }

      @include responsive(tab-port) {
        flex: 1 0 10%;
        padding: 0 2rem;
      }

      @include responsive(phone-port) {
        margin-right: 0 !important;
        justify-content: space-evenly;
      }

      p {
        color: #fffefc;
        margin-left: 5rem;
        font: $font-small-bold;

        @include responsive(tab-port) {
          margin-left: 2rem;
        }
      }
    }

    &__box-link {
      a {
        color: #fffefc;
        margin-left: 5rem;
        font: $font-small-bold;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        padding: 0 10rem;
        height: 10rem;
        border-radius: 5rem;

        @include responsive(tab-port) {
          margin-left: 2rem;
        }
      }
    }

    &__property {
      background: linear-gradient(
          to right,
          rgba(247, 159, 27, 0.8),
          rgba(213, 91, 15, 0.9)
        ),
        url("../../images/Homepage/property.png");
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 1rem;

      @include responsive(phone-port) {
        margin-right: 0 !important;
      }
    }

    &__perfection {
      background: linear-gradient(
          -44deg,
          rgba(0, 118, 224, 0.8),
          rgba(0, 50, 95, 0.9)
        ),
        url("../../images/Homepage/perfection.png");
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 1rem;
    }
  }

  .section-standfor {
    background-color: #d55b0f;
    &-img--1 {
      background: linear-gradient(
          to right,
          rgba(0, 31, 58, 0.8),
          rgba(0, 31, 58, 0.8)
        ),
        url("../../images/Homepage/Integrity.png");
      background-size: cover;
      width: 100%;
      height: 200px;
    }
    &-img--2 {
      background: linear-gradient(
          to right,
          rgba(0, 31, 58, 0.8),
          rgba(0, 31, 58, 0.8)
        ),
        url("../../images/Homepage/BestPractice.png");
      background-size: cover;
      width: 100%;
      height: 200px;
    }
    &-img--3 {
      background: linear-gradient(
          to right,
          rgba(0, 31, 58, 0.8),
          rgba(0, 31, 58, 0.8)
        ),
        url("../../images/Homepage/Simplicity.png");
      background-size: cover;
      width: 100%;
      height: 200px;
    }

    @include responsive(phone-port) {
      flex: 1 1 100%;
      margin-right: 0 !important;
    }

    p {
      padding: 2rem;
      text-align: center;
      font: $font-tiny;
      color: #e6e4e4;
    }

    h1 {
      padding-top: 1rem;
      color: #ffff;
      text-align: center;
      font: $font-small-bold;
      text-transform: uppercase;
    }
  }
}

.section-risk {
  &__heading {
    margin-left: 7rem;

    @include responsive(phone-port) {
      margin-left: 0;
    }
  }

  .risk-grid {
    justify-content: space-between;
  }

  &-box {
    padding: 5rem;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.07);
    margin-right: 0 !important;

    flex: 0 0 49% !important;

    min-height: 38rem;

    // display: flex;
    // align-items: center;

    // &:nth-child(2),
    // &:nth-child(4),
    // &:nth-child(6),
    // &:nth-child(8),
    // &:nth-child(10),
    // &:nth-child(12),
    //     &:nth-child(14) {
    //   margin-right: 0 !important;
    // }

    @include responsive(tab-land) {
      padding: 2rem;
    }

    @include responsive(tab-port) {
      flex: 1 0 47%;

      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(6) {
        margin-right: 0;
      }

      &:nth-child(3) {
        margin-right: $gutter-horizontal;
      }

      &:nth-child(4) {
        margin-right: 0;
      }
    }

    @include responsive(phone-port) {
      flex: 1 0 100% !important;

      margin-right: 0 !important;

      min-height: 200px;
    }

    h1 {
      font: $font-small-bold;
      margin: 2rem 0;
    }

    a {
      font: normal 500 1.2rem/2.2rem "Graphik";
      margin: 2rem 0;
    }
  }

  &--img {
    width: 50px;
  }
}

.who-invest-btn {
  display: flex;
  justify-content: center;
  width: 100%;

  a {
    width: 40%;

    button {
      width: 100%;
      padding-top: 2rem;
      padding-bottom: 2rem;
      font: $font-small-bold;
    }
  }
}

.middle-text {
  margin: 15rem 0;

  @include responsive(phone-port) {
    margin: 0;
  }
}

.professional-services-bk {
  background-color: #fff4ee;
  padding: 3rem 0 3rem 0;
  margin: 0 0 3rem 0;
}

.investor-img-left {
  flex: 0 0 25%;
}

.investor-text-right {
  flex: 1 1;
  margin-left: 5rem;
}

.investor-page-box {
  display: flex;
  align-items: center;
  border-bottom: #d55b0f 10px solid;
}

.investor-img-text {
  text-align: center;
}

.scenario-img {
  width: 240px;
  height: 240px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.totop {
  position: fixed;
  z-index: 998;
  bottom: 10px;
  right: 25px;
  display: block;
  width: 80px;
  height: 40px;
  line-height: 40px;
  background-color: #D55B0F;
  color: #fff;
  text-align: center;
  // text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
  font-size: 16px;
}

.animated {
  position: absolute;
  // z-index: 998;
  // margin: auto;
  right: 60px;
  // left: 0;
  bottom: 15px;
  height: 4px;
  width: 4px;
  // z-index: 998;
  // bottom: 10px;
  // right: 60px;
  // display: block;
  // width: 4px;
  // height: 4px;
  border-radius: 4px;
  background: #D55B0F;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}

.anim span::before,
.anim span::after {
  position: absolute;
  top: -23px;
  content: "";
  height: 30px;
  width: 10px;
  border-radius: 4px;
  background: #D55B0F;
}

.anim span::before {
  left: -10px;
  -webkit-transform: rotate(-45deg);
  -moz-transform:    rotate(-45deg);
  -ms-transform:     rotate(-45deg);
  -o-transform:      rotate(-45deg);
  transform:         rotate(-45deg);
}

.anim span::after {
  right: -10px;
  -webkit-transform: rotate(45deg);
  -moz-transform:    rotate(45deg);
  -ms-transform:     rotate(45deg);
  -o-transform:      rotate(45deg);
  transform:         rotate(45deg);
}

.bounce {
  animation-name: bounce;
  animation-timing-function: ease;
}
@keyframes bounce {
  0%   { transform: translateY(0); }
  50%  { transform: translateY(-20px); }
  100% { transform: translateY(0); }
}