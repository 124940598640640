.dashboard {
    // padding: 2rem;
    background-color: rgba(240, 245, 248, 0.904);
    max-width: 100%;
    height: 100vh;
    overflow: hidden;
    // margin-bottom: 30rem;

    @include responsive(tab-port) {
        overflow: initial;
        height: 100vh;
    }
}

.dashboard-box {
    border: 2px solid #eaeaea81;
    max-width: 100%;

    &__grid {
        display: flex;
        // padding-top: 2rem;

        @include responsive(tab-port) {
            flex-direction: column;
        }
    }
}

.dashboard-box-admin {
  border-top: 2px solid #eaeaea81;
  max-width: 100%;

  &__grid {
      display: flex;
      // padding-top: 2rem;

      @include responsive(tab-port) {
          flex-direction: column;
      }
  }
}

.main-view {
    flex: 1;
    margin-top: 2rem;
    padding-left: 4rem;
    padding-right: 4rem;
    height: 100vh;
    overflow-y: scroll;
    padding-bottom: 8rem;
    // background-color: hotpink;

    @include responsive(tab-port) {
        overflow: initial;
        padding-bottom: 4rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.main-view-investor {
  flex: 1;
  margin-top: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;
  height: 100vh;
  overflow-y: scroll;
  padding-bottom: 18rem;
  // background-color: hotpink;

  @include responsive(tab-port) {
      overflow: initial;
      padding-bottom: 4rem;
      padding-left: 1rem;
      padding-right: 1rem;
  }
}