.asset {
  &-header {
    padding: 20px 5px;
    // background-color: yellow;
  }
  &-title {
    font-size: 28px;
    margin-bottom: 15px;
    text-align: center;
  }

  &-indentradio {
    margin-left: 30px;
  }
  &-payment-lawyer {
    padding: 20px;
  }

  &-dropzone {
    background-color: white;
    text-align: center;
    cursor: pointer;
  }

  &-image {
    &-box {
      height: 200px;
      width: 210px;
      background: white; 
      border: dashed 1px black;
      cursor: pointer;
    }
    &-box-fill {
      height: 200px;
      width: 210px;
      cursor: pointer;
      border: none;
    }

    &-box-add {
      height: 200px;
      width: 210px;
      background: white; 
      border: dashed 1px black;
      cursor: pointer;
    }
    &-box-fill-add {
      height: 200px;
      width: 210px;
      cursor: pointer;
      border: none;
    }

    &__icon {
      margin: 0 auto;
      margin: 80px 20px;
      cursor: pointer;
    }
    display: flex;
    justify-content: center;
    padding: 10px;

    @include responsive(phone-port) {
      flex-direction: column;
      align-items: unset;
      justify-content: center;
      padding: 0px;
      margin-top: 5px;

      &-box {
        background: white; 
        border: dashed 1px black;
        cursor: pointer;
        margin-top: 15px;
      }

      &-box-add {
        height: 200px;
        width: 210px;
        background: white; 
        border: dashed 1px black;
        cursor: pointer;
        margin-left: -35px;
        margin-top: 15px;
      }

      &-box-fill-add {
        height: 200px;
        width: 210px;
        cursor: pointer;
        border: none;
        margin-left: -35px;
        margin-top: 15px;
      }

      &__icon {
        margin: 0 auto;
        margin: 80px 20px;
        cursor: pointer;
        // display: none;
        // margin: 0 auto;
        // margin: 0px 0px;
        // cursor: pointer;
        // font-size: 2px!important;
      }
    }
  }
}

.asset-dashboard {
  // padding: 2rem;
  background-color: rgba(240, 245, 248, 0.904);
  max-width: 100%;
  height: 100vh;
  // overflow: hidden;
  // margin-bottom: 30rem;

  @include responsive(tab-port) {
      // overflow: initial;
      height: 100vh;
  }
}

.asset-dashboard-box {
  border-top: 2px solid #eaeaea81;
  max-width: 100%;

  // &__grid {
  //     display: flex;
  //     // padding-top: 2rem;

  //     @include responsive(tab-port) {
  //         flex-direction: column;
  //     }
  // }
}

.asset-main-view {
  flex: 1;
  margin-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  height: 100vh;
  overflow-y: scroll;
  padding-bottom: 10rem;
  // background-color: hotpink;
  // width: 100%;

  @include responsive(tab-port) {
      // overflow: initial;
      padding-bottom: 4rem;
      padding-left: 1rem;
      padding-right: 1rem;
  }
}

.asset-top {
  display: flex;
  // align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8rem;
  padding-bottom: 8rem;

  @include responsive(tab-port) {
    flex-direction: column;
    align-items: unset;
    justify-content: center;
  }
  @include responsive(phone-port) {
    flex-direction: column;
    align-items: unset;
    justify-content: center;
  }
}

.asset-review__wrap {
  // display: flex;
  width: 100%;
  @include responsive(tab-port) {
    // justify-content: space-between;
    width: 100%;
    margin-top: 1rem;
  }
  @include responsive(phone-port) {
    // justify-content: space-between;
    width: 100%;
    margin-top: 1rem;
    padding-right: 2rem;
    // padding-left: -1rem;
  }
}

.asset-details {
  // flex: 0 0 50%
  width: 60%;
  @include responsive(phone-port) {
    // justify-content: space-between;
    width: 100%;
    margin-top: 1rem;
  }
}

.asset-file {
  &__card {
    padding: 1rem 2rem;
    box-shadow: 0 10px 35px rgba(0, 32, 60, 0.05);
    margin-bottom: 3rem;
    background-color: #f6f8fc;
  }

  &__info {
    color: #d55b0f;
  }

  &__img {
    cursor: pointer;
    position: fixed;
    margin: 0 auto;

    
  }

  &__box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }
}

.asset-upload > input {
  display: none;
}

.asset-form {
  background: #fff;
  flex: 0 0 45rem;
  border-radius: 5px;
  padding: 7rem 4rem 3rem 4rem;
  align-self: flex-start;
  margin-left: 20px;
  margin-right: 10px;

  &__heading {
    font: $font-small-bold;
    margin-bottom: 2rem;
  }

  &--input {
    padding: 1rem 1rem;
    font: $font-tiny;
    width: 100%;
    border: none;
    resize: none;
  }

  textarea {
    overflow-y: scroll;
    height: 100px;
  }

  &--btn {
    width: 100%;
    margin: 3rem 0;
  }
}

// .asset-img-section {
//   width: 40%;
//   padding-bottom: 20rem!important;
// }
.asset-full {
  // width: 30%;
  padding: 2rem;
  border-radius: 5px;
  background-color: #fff;


  @include responsive(tab-port) {
    width: 40%;
  }
  @include responsive(phone-port) {
    width: 100%;
    height: 200px;
  }
  @include responsive(big-desktop) {
    width: 100%;
  }

  &__image {
    width: 40%;
    padding-right: 20px;
    padding-left: 20px;

    @include responsive(phone-port) {
      width: 100%;
      // height: 300px;
      // padding: 1rem 0rem 1rem 1rem;
    }

    img {
      width: 100%;
      height: 350px;
      // height: 400px;
      border-radius: 5px;

      @include responsive(phone-port) {
        width: 100%;
        height: 300px;
        padding: 1rem 0rem 1rem 1rem;
      }
    }
  }

  &__header {
    color: #de6c31;
    font: $font-small-semi;
    margin: 1rem 0;
    text-align: center;
    text-transform: capitalize;
  }

  &__description {
    font: $font-tiny;
    text-align: center;
    height: 8.8rem;
    overflow: hidden;
  }

  &:not(:last-child) {
    margin-right: 20px;
    min-width: 8.5rem;
  }

  &__funds {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font: $font-tiny-semi;
    }

    h2 {
      font: $font-small;
      color: #5c5c5c;
    }
  }

  &-returns {
    margin-top: -10px;
  }

  &__bottom {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &-raised {
    text-align: center;

    &:not(:last-child) {
      margin-right: 5px;
    }

    h3 {
      font: $font-tiny-semi;
      font-size: 1rem;
      text-transform: uppercase;
    }

    h2 {
      font: $font-small;
      color: #5c5c5c;
    }

    &::last-child {
      flex: 1;
    }
  }
  margin-bottom: 20px;

  &__button {
    float: right;
    margin: 2rem 0;
    background-color: #fff;
    border: 1px solid $primary-color;
    border-radius: 100px;
    padding: 0.7rem;
    width: 10rem;
    margin-right: 0.5rem;
  }
}

.asset__btn--approval {
  background-color: #e6e6e6;
  // margin-left: 4rem;
  // width: 100%;
}

.asset-reject__btn {
  display: block;
  width: 100%;
  padding: 0.5rem 2rem;
  background-color: red;
  margin-bottom: 3rem;
  color: #fff;
  border: none;
  font: $font-small-semi;
  border-radius: 3px;

  @include responsive(phone-port) {
    padding: 1rem 2rem;
  }
}

.asset-waiting__btn {
  display: block;
  width: 100%;
  padding: 0.5rem 2rem;
  background-color: gray;
  margin-bottom: 3rem;
  color: #fff;
  border: none;
  font: $font-small-semi;
  border-radius: 3px;

  @include responsive(phone-port) {
    padding: 1rem 2rem;
  }
  cursor: progress;
}

.asset-review__btn {
  display: block;
  width: 100%;
  padding: 0.5rem 2rem;
  background-color: #1779ba;
  margin-bottom: 3rem;
  color: #fff;
  border: none;
  font: $font-small-semi;
  border-radius: 3px;

  @include responsive(phone-port) {
    padding: 1rem 2rem;
  }
}

.asset-approve__btn {
  display: block;
  width: 100%;
  padding: 0.5rem 2rem;
  background-color: #40a160;
  margin-bottom: 3rem;
  color: #fff;
  border: none;
  font: $font-small-semi;
  border-radius: 3px;

  @include responsive(phone-port) {
    padding: 1rem 2rem;
  }
}

.asset-admin__btn {
  display: block;
  width: 100%;
  padding: 0.5rem 2rem;
  background-color: #d55b0f;
  margin-bottom: 3rem;
  color: #fff;
  border: none;
  font: $font-small-semi;
  border-radius: 3px;

  @include responsive(phone-port) {
    padding: 1rem 2rem;
  }
}

.asset-items {
  margin-left: 4rem;
  margin-right: 4rem;

  width: 90%;
  // overflow:hidden;
  word-wrap: break-word
}

.asset-insight {
  display: flex;
  flex-wrap: wrap;
}

.search-doc {
  padding-top: 10px;
  padding-left: 46px;

  &-head {
    padding: 10px;
  }
}

.search-doc-btn {
  padding-top: -10px;
  padding-left: 46px;
}

.asset-first {
  &__text {
    margin-left: 6rem;
    margin-right: 6rem;
  }
}

.asset-footer {
  
  margin-bottom: 0rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-btn--proceed {
    width: 12rem;
  }

  &-btn--back {
    visibility: hidden;
  }
}


.asset-reference {

  background: #f6f8fc;
  margin-left: 6rem;
  margin-right: 6rem;
  padding: 3rem;

  &__text {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  &__input {
    background: #f6f8fc;
    margin-left: 6rem;
    margin-right: 6rem;
  }
}

.asset-field {
  max-width: 90%;
  margin: auto;
  p {
    font: $font-small;
    margin-bottom: 40px;
  }

  &__code {
    flex: 0 0 10% !important;
    position: relative;

    label {
      width: 100%;
      left: 0;
      text-align: left;
    }
  }

  &-box {
    flex: 1;
    text-align: left;
    width: 97%;
  }

  &--input {
    padding: 1rem 1rem;
    font: $font-tiny;
    width: 100%;
    margin-top: 1rem;
    border: none;
    border: 1px solid #d9d9d9;
    border-radius: 0.5rem;
    background-color: #f6f8fc;
    resize: none;
  }

  &__input {
    width: 100%;
    padding: 1.5rem 0.5rem;
    border: none;
    font: $font-tiny-bold;
    font-size: 1.4rem;
    outline: none;
    color: $primary-text-color;
    border: 2px solid transparent;
    display: block;
    border-radius: 5px;
    transition: all 0.3s;

    &::-webkit-input-placeholder {
      color: #8e8e8e;
      font: $font-tiny;
      text-transform: capitalize;
    }

    &:focus {
      box-shadow: 0 1rem 2rem rgba($color: #000000, $alpha: 0.1);
      border: 2px solid $primary-btn-color;
    }

    &:focus:invalid {
      border: 2px solid $primary-color;
    }

    &--address {
      padding-bottom: 4rem;
    }
  }

  &__label {
    position: absolute;
    padding-left: 1.4rem;
    margin-top: 0.5rem;
    font: $font-tiny;
    font-size: 1rem;
    text-transform: capitalize;
    color: #8e8e8e;
    transition: all 0.3s;
    transform: translateY(-6.5rem);
  }

  &__input:placeholder-shown + &__label {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-4rem);
  }

  &__input--address + &__label {
    transform: translateY(-13rem);
  }

  input[type="date"] {
    &::-webkit-datetime-edit-fields-wrapper {
      color: $primary-text-color;
      font: $font-tiny-bold;
    }
    &::-webkit-inner-spin-button {
      display: none;
    }
  }
}


.preloader-background {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.preloader-background.p {
  padding-top:120px;
  margin-left: -60px;
  opacity: 0.8;
} 
.blinking {
    font-size: 40px;
    color: #304ffe;
    animation: blinker 1.5s linear infinite;
}
@keyframes blinker {  
  50% { opacity: 0; }
}

.badge-noti {
  display: inline-block;
  // width: 30px;
  border-radius: 50%;
  font-size: 3px;
  text-align: center;
  background: #d55b0f;
  color: #fefefe;
  font-size: 10px;
  font-weight: bold;
  padding: 0 1rem;
}

.asset-heading {
  color: $primary-text-color;
  max-width: 60.7rem;
  margin: 4rem 0 2rem 0;
  font: $font-custom-heading;

  @include responsive(phone-port) {
    font-size: 15px;
  }
}
.asset-section {
  padding-top: 5px
}