table {
  border-spacing: 0;
  border: 1px solid #cac8c8d5;
}
table tr:last-child td {
  border-bottom: 0;
}
table th,
table td {
  margin: 0;
  padding: 0.2rem 1rem;
  border-bottom: 1px solid #cac8c8d5;
  border-right: 1px solid #cac8c8d5;
  position: relative;
}

table th:last-child,
table td:last-child {
  border-right: 0;
}
table tr:nth-child(odd) {
  background-color: #fafafa;
}

table th::before {
  position: absolute;
  right: 15px;
  top: 16px;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
table th.sort-asc::before {
  border-bottom: 5px solid #22543d;
}
table th.sort-desc::before {
  border-top: 5px solid #22543d;
}

.App {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 80px;
}

.table-badge {
  background-color: #9ae6b4;
  color: #22543d;
  margin-right: 4px;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.table-input {
  padding: 10px;
  margin-bottom: 20px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: none;
}

.search-input {
  margin-bottom: 10px;
}

.scroll-table {
  overflow:scroll;
}

.dRight {
  padding-top: 2rem;
  padding-bottom: 2rem;

}

.paginate {
  padding-top: 2rem;
  padding-bottom: 2rem;
  float: right;
}


.messages-wrap {
  display: flex;
  width: 100%;

  @include responsive(tab-port) {
    justify-content: space-between;
    margin-top: 1rem;
  }
}

.attorn-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}


.admin-grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  background-color: #2196F3;
  padding: 10px;
}
.admin-grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 20px;
  font-size: 30px;
  text-align: center;
}