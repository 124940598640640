.investor-insight {
    display: flex;
    flex-wrap: wrap;
    padding-top: 40px;
}


.investor-insight-dsr {
  // display: flex;
  
  flex-wrap: wrap;
  padding-top: 40px;
}

.investor-insight-public {
  display: flex;
  flex-wrap: wrap;
  // padding: 40px;
  padding-left: 60px;

  @include responsive(phone-port) {
    padding-left: 20px;

}
}