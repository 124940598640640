.search {
  &-sub {
    font-size: 18px;
    margin-top: -20px;
  }
  &-mob {
    font-size: 22px;
    margin-top: -20px;
  }
}

.search-header {
  min-height: 100vh;
  background-color: black;

  @include responsive(tab-port) {
    min-height: 0;
    height: 60vh;
  }
  &-home {
    background: linear-gradient(
        to right,
        rgba(0, 31, 58, 0.8),
        rgba(0, 31, 58, 0.8)
      ),
      url("../../images/Homepage/HomePage.png");
    background-size: cover;
    width: 100%;

    button {
      display: inline-block;
    }
  }
  &__content {
    max-width: 75%;
    margin: 0 auto;
    padding-bottom: 10rem;
    // position: absolute;
    // top: 65%;
    // left: 50%;
    // transform: translate(-50%, -65%) ;
  }

  &__content-inv {
    // max-width: 67rem;
    margin-right: 0 20px;
    padding-bottom: 10rem;
    text-align: right;
    // position: absolute;
    // top: 65%;
    // left: 50%;
    // transform: translate(-50%, -65%) ;
  }

  &__content-box {
    margin-top: 217px;
    color: #fff;

    @include responsive(tab-port) {
      margin-top: 10rem;
    }
  }
}

// .banner .nav-tabs {
//   border: none;
//   background: rgba(0,0,0,.5);
//   margin-bottom: 5px;
//   display: inline-block;
//   width: 100%;
//   padding: 0;
// }

// .banner * {
//   position: relative;
//   z-index: 2;
// }

// .navbar-nav {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-orient: vertical;
//   -webkit-box-direction: normal;
//   -ms-flex-direction: column;
//   flex-direction: column;
//   padding-left: 0;
//   margin-bottom: 0;
//   list-style: none;
// }

.search-input {

  &-box {
    flex: 1;
    text-align: left;
    width: 97%;
    
    @include responsive(phone-port) {
      margin-left: -15px;
      width: 100%;
    }
  }

  &__input {
    width: 40%;
    padding: 1.5rem 1.4rem;
    border: none;
    font: $font-tiny-bold;
    font-size: 1.4rem;
    outline: none;
    color: $primary-text-color;
    border: 3px solid transparent;
    transition: all 0.3s;

    &::-webkit-input-placeholder {
      color: #8e8e8e;
      font: $font-tiny;
      text-transform: capitalize;
    }

    &:focus {
      box-shadow: 0 1rem 2rem rgba($color: #000000, $alpha: 0.1);
      border: 2px solid $primary-btn-color;
    }

    &:focus:invalid {
      border: 2px solid $primary-color;
    }
    &--address {
      padding-bottom: 4rem;
    }
  }
}

.search-to {

  &-box {
    flex: 1;
    text-align: left;
    width: 97%;
    
    @include responsive(phone-port) {
      margin-left: -15px;
      width: 100%;
    }
  }

  &__input {
    width: 40%;
    padding: 1.5rem 1.4rem;
    border: none;
    font: $font-tiny-bold;
    font-size: 1.4rem;
    outline: none;
    color: $primary-text-color;
    border: 3px solid transparent;
    transition: all 0.3s;

    &::-webkit-input-placeholder {
      color: #8e8e8e;
      font: $font-tiny;
      text-transform: capitalize;
    }

    &:focus {
      box-shadow: 0 1rem 2rem rgba($color: #000000, $alpha: 0.1);
      border: 2px solid $primary-btn-color;
    }

    &:focus:invalid {
      border: 2px solid $primary-color;
    }
    &--address {
      padding-bottom: 4rem;
    }
  }
}


.search-select {
  
  &-label {
    text-align: left;
    // display: block;
    font: $font-tiny-bold;
  }

  &-amount {
    font: $font-tiny-bold;
    padding: 1.7rem 1.4rem 1.7rem 1.2rem;
    width: 13%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #f2f2f2;
    border-radius: 0px;
    color: $primary-text-color;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: #fff;
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;

    &::-ms-expand {
      display: none;
    }
    &:hover {
      border-color: #888;
    }
    &:focus {
      border-color: #aaa;
      box-shadow: 0 0 1px 2px $primary-color;
      box-shadow: 0 0 0 3px -moz-mac-focusing;
      outline: none;
    }
    & option {
      font-weight: normal;
    }

    &--icon {
      position: absolute;
      top: 65.5%;
      left: 80%;
      width: 10px;
    }
  }

  &-top {
    font: $font-tiny-bold;
    padding: 1.7rem 1.4rem 1.7rem 1.2rem;
    width: 11%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #f2f2f2;
    border-radius: 0px;
    color: $primary-text-color;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: #fff;
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;

    &::-ms-expand {
      display: none;
    }
    &:hover {
      border-color: #888;
    }
    &:focus {
      border-color: #aaa;
      box-shadow: 0 0 1px 2px $primary-color;
      box-shadow: 0 0 0 3px -moz-mac-focusing;
      outline: none;
    }
    & option {
      font-weight: normal;
    }

    &--icon {
      position: absolute;
      top: 65.5%;
      left: 80%;
      width: 10px;
    }
  }

  &-normal {
    font: $font-tiny-bold;
    padding: 1.7rem 1.4rem 1.7rem 1.2rem;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #f2f2f2;
    border-radius: 2px;
    color: $primary-text-color;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: #fff;
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
    // margin-left: 10px;

    @include responsive(phone-port) {
      // width: 100%;
      // max-width: 100%;
      // flex: 0 0 30% !important;
      // margin-right: 0 !important;
      // margin-left: 5px;
    }

    &::-ms-expand {
      display: none;
    }
    &:hover {
      border-color: #888;
    }
    &:focus {
      border-color: #aaa;
      box-shadow: 0 0 1px 2px $primary-color;
      box-shadow: 0 0 0 3px -moz-mac-focusing;
      outline: none;
    }
    & option {
      font-weight: normal;
    }
    &--icon {
      position: absolute;
      top: 65.5%;
      left: 80%;
      width: 10px;
    }
  }
}

.drop {
  flex: 0 0 45% !important;
  position: relative;

  label {
    width: 30%;
    left: 0;
    text-align: left;
  }

  @include responsive(phone-port) {
    flex: 0 0 50% !important;
    align-items: center;
    // margin-right: 0 !important;
  }
}

// .drop-form {
//   flex: 0 0 10% !important;
//   position: relative;

//   // float: left;

//   label {
//     width: 30%;
//     left: 0;
//     text-align: left;
//   }

//   @include responsive(phone-port) {
//     flex: 0 0 30% !important;
//     // margin-right: 0 !important;
//   }
// }



/* The container must be positioned relative: */
.custom-select {
  position: relative;
  font-family: Arial;
  // background: white;
}

// .custom-select select {
//   display: none; /*hide original SELECT element: */
// }

.select-selected {
  background-color: DodgerBlue;
}

/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/* style the items (options), including the selected item: */
.select-items div,.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

/* Style items (options): */
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}


.top-search {
  margin-left: 40px!important;
  margin-bottom: 30px!important;
  margin: 10px;

  &__input {
    width: 30%;
    padding: 1.5rem 1.4rem;
    border: none;
    font: $font-tiny-bold;
    font-size: 1.4rem;
    outline: none;
    color: $primary-text-color;
    border: 2px solid #f2f2f2;
    transition: all 0.3s;

    &::-webkit-input-placeholder {
      color: #8e8e8e;
      font: $font-tiny;
      text-transform: capitalize;
    }

    &:focus {
      box-shadow: 0 1rem 2rem rgba($color: #000000, $alpha: 0.1);
      border: 2px solid $primary-btn-color;
    }

    &:focus:invalid {
      border: 2px solid $primary-color;
    }
    &--address {
      padding-bottom: 4rem;
    }
  }
}

@media (min-width: 768px) {
  .mobile-search {
    display: none;
  }
  .mobile-home-items {
    display: none;
  }
  .desktop-search {
  }
  .desktop-home-items {
  }
}

@media (max-width: 768px) {
  .desktop-search {
    display: none;
  }
  .desktop-home-items {
    display: none;
  }
  .mobile-search {
  }
  .mobile-home-items {
  }
}

.grid-search {
  display: grid;
  grid-template-columns: auto auto;
  background-color: transparent;
  padding: 10px;
  // opacity: 50%;
}
.grid-search-item {
  // background-color: rgba(255, 255, 255, 0.8);
  border: 0.1px solid #f2f2f2;
  padding: 14px;
  font-size: 14px;
  text-align: center;
  margin: 20px 20px 10px 0px;
  // width: 70%;
  width: 100px;
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
}


