.sidebar {
	flex: 0 0 12%;
	background-color: #fff;
	border-right: 2px solid #eaeaea81;
	min-height: 100vh;

	@include responsive(tab-port) {
		min-height: 0;
	}
    
}

.sidebar-box {

  padding-top: 2rem;
  
	@include responsive(tab-port) {
		display: flex;
		justify-content: space-evenly;
		padding-top: 0;
		// padding-left: 2rem;
  }
  // padding-right: 2rem;
  
  padding-bottom: 10rem; 
  height: 600px;
  overflow-x: hidden; 
  overflow-y: auto; 

  @include responsive(phone-port) {
		display: flex;
		justify-content: space-evenly;
    padding-top: 0;
    padding-bottom: 0rem; 
    height: 10px;
  }
}


@media (min-width: 768px) {
 
  .sidebar-nav {
    h1 {
      margin-left: 2rem;
      padding: 1rem 0;
      font: $font-tiny;
      font-weight: 400;
      color: #6E6E6E;

      @include responsive(tab-port) {
        margin: 0 1.2rem;
        font: $font-small-semi;
      }
    }
    
    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &.active {
      background-color: #F9FBFE;
      border-left: 3px solid #DE6C31;
      transition: .3s;

      @include responsive(tab-port) {
        border-bottom: 3px solid #DE6C31;
        border-left: unset;
      }

      h1 {
        margin-left: 1.7rem !important;
        transition: .3s;

        @include responsive(tab-port) {
          margin-right: 1.7rem !important;
        }
      }
    }
  }
  .sidebar-nav-btn {

    margin-left: 2rem;
    padding: 1rem 0;
    font: $font-tiny;
    font-weight: 400;
    color: #6E6E6E;
    border: none;
    padding: 0;
    height: auto;
    cursor: pointer;
    background: #fff;
    @include responsive(tab-port) {
      margin: 0 1.2rem;
      font: $font-small-semi;
    }
  }

  .side-nav {
    display: none;
  }
  // .card-button-link {
  //   width: 80px;
  //   background-color: inherit;
  //   border: none;
  //   padding: 0;
  //   height: auto;
  //   color: #D55B0F;
  //   // font-size: 12px;
  //   font-size: 1.33rem;
  // }
}


@media (max-width: 768px) {

  .sidebar-nav {
    display: none;
  }
  .sidebar-nav-btn {
    display: none;
  }
}
