.title {
  background: #f6f8fc;
  background-size: cover;
  // min-height: 100vh;
}
// .title-form {
//   &__input {
//     background: #f6f8fc;
//     min-height: 75vh;
//     padding-top: 20px;
//   }
//   &__payment {
//     background: #f6f8fc;
//     min-height: 30vh;
//     padding-top: 20px;
//   }
// }

.title-form__reset {
  margin-bottom: 0rem !important;
}
.submit-footer {
  display: flex;
  justify-content: space-between;
  margin-left: 6rem;
  margin-right: 6rem;

  margin-bottom: 0rem;
  padding-bottom: 6rem;
}

.title-payment {
  margin-bottom: 40px;
}

.title-form {
  max-width: 90%;
  margin: auto;
  p {
    font: $font-small;
    margin-bottom: 40px;
  }

  &__code {
    flex: 0 0 15% !important;
    position: relative;

    label {
      width: 100%;
      left: 0;
      text-align: left;
    }
  }

  &-box {
    flex: 1;
    text-align: left;
    width: 97%;
    
    // @include responsive(tab-port) {
    //   width: 40%;
    // }

    @include responsive(phone-port) {
      margin-left: -15px;
      width: 100%;
    }

    // @include responsive(big-desktop) {
    //   width: 100%;
    // }
  }

  &--input {
    padding: 1rem 1rem;
    font: $font-tiny;
    width: 100%;
    margin-top: 1rem;
    border: none;
    border: 1px solid #d9d9d9;
    border-radius: 0.5rem;
    background-color: #f6f8fc;
    resize: none;
  }

  textarea {
    overflow-y: scroll;
    height: 100px;
  }

  &__payment {
    text-align: center;
    width: 100%;
    padding: 1.5rem 1.4rem;
    border: none;
    font: $font-tiny-bold;
    font-size: 1.4rem;
    outline: none;
    color: $primary-text-color;
    border: 2px solid transparent;
    display: block;
    border-radius: 5px;
    transition: all 0.3s;

    &::-webkit-input-placeholder {
      color: #8e8e8e;
      font: $font-tiny;
      text-transform: capitalize;
    }

    &:focus {
      box-shadow: 0 1rem 2rem rgba($color: #000000, $alpha: 0.1);
      border: 2px solid $primary-btn-color;
    }

    &:focus:invalid {
      border: 2px solid $primary-color;
    }
  }

  &__input {
    width: 100%;
    padding: 1.5rem 1.4rem;
    border: none;
    font: $font-tiny-bold;
    font-size: 1.4rem;
    outline: none;
    color: $primary-text-color;
    border: 2px solid transparent;
    display: block;
    border-radius: 5px;
    transition: all 0.3s;

    &::-webkit-input-placeholder {
      color: #8e8e8e;
      font: $font-tiny;
      text-transform: capitalize;
    }

    &:focus {
      box-shadow: 0 1rem 2rem rgba($color: #000000, $alpha: 0.1);
      border: 2px solid $primary-btn-color;
    }

    &:focus:invalid {
      border: 2px solid $primary-color;
    }

    &--address {
      padding-bottom: 4rem;
    }
  }

  &__label {
    position: absolute;
    padding-left: 1.4rem;
    margin-top: 0.5rem;
    font: $font-tiny;
    font-size: 1rem;
    text-transform: capitalize;
    color: #8e8e8e;
    transition: all 0.3s;
    transform: translateY(-6.5rem);
  }

  &__input:placeholder-shown + &__label {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-4rem);
  }

  &__input--address + &__label {
    transform: translateY(-13rem);
  }

  input[type="date"] {
    &::-webkit-datetime-edit-fields-wrapper {
      color: $primary-text-color;
      font: $font-tiny-bold;
    }
    &::-webkit-inner-spin-button {
      display: none;
    }
  }
}


.title-form-image {

  max-width: 90%;
  margin: auto;
  
  &-box {
    flex: 1;
    text-align: left;
    width: 97%;
    margin-left: -70px;
    
    @include responsive(phone-port) {
      width: 100%;
      margin-left: -20px;
    }
  }
}

.title-form-amount {
  min-height: 15vh;
  padding-top: 20px;
  background: #f6f8fc;
}

.form-space {
  margin-top: 10px;
}
.title-form-section {
  background: #ffffff;
}

.title-header {
  background: #ffff;
  margin-bottom: 60px !important;
}

.title-section {
  text-align: center;
}

.title-heading {
  color: $primary-text-color;
  max-width: 60.7rem;
  margin: 4rem 0 2rem 0;
  font: $font-custom-heading;
  // margin-top: 30px;
}

.title-heading-center {
  color: $primary-text-color;
  margin: 4rem 0 2rem 0;
  font: $font-custom-heading;
  text-align: center;
}

.title-heading-2 {
  color: $primary-text-color;
  margin: 4rem 0 2rem 0;
  font: $font-custom-heading;
  // margin-top: 30px;
  text-align: center;
}

.title-sub-heading {
  margin-bottom: 1rem;
}

.title-sub-heading-2 {
  margin: 2rem auto;
  max-width: 40rem;
  text-align: center;
  font-size: 1.4rem;
}

.title-sub-heading-prof-service {
  margin: 4rem auto;
  max-width: 50rem;
  text-align: center;
  font-size: 1.4rem;
}

.title-sub___head {
  margin-bottom: 2rem;
}

.col-1-title {
  width: 10%;
  display: flex;
  flex-direction: column;
}

.col-2-title {
  background-color: #ffff;
  text-align: start;
  border-bottom: 0.5rem solid;
  border-radius: 4px;
  border-color: #d55b0f;
}

.title-heading {
  margin-left: 0;
}

.title-first {
  &__text {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  &__input {
    background: #f6f8fc;
    margin-left: 6rem;
    margin-right: 6rem;

    @include responsive(phone-port) {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    // &__payment {
    //   background: #ffffff;
    //   margin-left: 6rem;
    //   margin-right: 6rem;
    // }
  }
  margin-left: 10rem;
  margin-right: 20rem;
}

.title-first-file {
  margin-left: 5rem;
  margin-right: 5rem;
}

.title-button {
  margin-top: 4rem;
  margin-bottom: 4rem;

  &__submit {
    margin-top: 0rem;
    margin-bottom: 0rem;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  &__loader {
    padding: 2rem;
  }
}

.title-button-transparent {
  background-color: transparent;
  border-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d55b0f;
  cursor: pointer;
}

.title-sub {
  color: #7c7c7c;
  &__loan {
    margin-bottom: 0.5rem;
  }
  &__payment {
    margin-bottom: 1rem;
  }
  &__check-payment {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
  }
}

.title__input-radio {
  input {
    width: 10%;
  }

  img {
    width: 100px;
  }
}

.title__line {
  background-color: #7c7c7c;
  margin-bottom: 2rem;

  &-space {
    margin-top: 2rem;
  }
}

.title-footer {
  // position: fixed;

  // padding-right:0 10rem;
  // margin: 4rem 10rem;
  // margin-left: 7rem;
  margin-bottom: 0rem;
  padding-bottom: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-btn--proceed {
    width: 12rem;
  }

  &-btn--back {
    visibility: hidden;
  }
}

.backButton {
  display: flex;
  align-self: center;
  // justify-content: space-between;
  // align-items: center;
}

.back-text {
  color: #d55b0f;
  text-transform: uppercase;
  margin-left: 0.4rem;
}

.loan_amount {
  color: #d55b0f;
  font-size: 5rem;
}

.title-card {
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 1rem;
  margin-right: 0;

  &__checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  &__checkbox:checked ~ &__disc {
    border: 1px solid $primary-color;
  }
  &__label {
    display: flex;
  }
  &__disc {
    margin-top: 8px;
    height: 104px;
    padding: 2rem;
    width: 36rem;
    background-color: #f6f8fc;
  }
  &__description {
    padding-bottom: 0.5rem;
    font-size: 15px;
  }
  &__company {
    margin-top: -30px;
    padding-bottom: 3rem;
  }

  &__rate {
    color: #d55b0f;
  }
  &.active {
    border-color: red;
    // border-radius: 5px;
    border: 1px solid $primary-color;
  }
}
.activeColor {
  border: 1px solid $primary-color;
}
.motor {
  border: 1px solid green;
}
// .mine {
//   border-color:  red;
//   // border-radius: 5px;
//   border: 1px solid $primary-color
// }

.image-size {
  border: 1px transparent #f2f2f2;
  border-radius: 5px;
  width: 101px;
  height: 119px;
}

.title-att__header {
  display: flex;
  justify-content: space-between;
}

.image-upload > input {
  display: none;
}
.image-upload-m {
  margin-right: 10px!important;
} 



.section-file-upload {
  &__card {
    padding: 1rem 2rem;
    box-shadow: 0 10px 35px rgba(0, 32, 60, 0.05);
    margin-bottom: 3rem;
    background-color: #f6f8fc;
  }

  &__info {
    color: #d55b0f;
  }

  &__img {
    cursor: pointer;
  }

  &__box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }
}

.break-down {
  background-color: #f6f8fc;

  &__box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    padding: 1rem;
    background-color: #f6f8fc;
  }
  &__amount {
    // font-size: 15px;
  }
  &__name {
    // font-size: 15px;
  }
}

.title-delivery {
  // display: flex;
  // flex-direction: row;
}

.title-form {

  &--input {
    padding: 1rem 1rem;
    font: $font-tiny;
    width: 100%;
    margin-top: 1rem;
    border: none;
    border: 1px solid #d9d9d9;
    border-radius: 0.5rem;
    background-color: #f6f8fc;
    resize: none;
  }

  textarea {
    overflow-y: scroll;
    height: 100px;
  }
}

.title__amount {
  display: flex;
  flex-direction: row;
}

.title__delivery {
  margin-left: 1rem;
}

.optCode {
  padding: 10px 10px;
}

.title-investment {
  &-label {
    text-align: left;
    display: block;
    font: $font-tiny-bold;
    // padding: 1.7rem 0.4rem 1.7rem 1.0rem;
  }

  &-amount {
    display: block;
    font: $font-tiny-bold;
    // height: 100%;
    padding: 1.7rem 0.4rem 1.7rem 1rem;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 0;
    color: $primary-text-color;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: #fff;
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;

    &::-ms-expand {
      display: none;
    }
    &:hover {
      border-color: #888;
    }
    &:focus {
      border-color: #aaa;
      box-shadow: 0 0 1px 2px $primary-color;
      box-shadow: 0 0 0 3px -moz-mac-focusing;
      outline: none;
    }
    & option {
      font-weight: normal;
    }
  }
}

.title-perfect {
  margin-top: 20px;
  &-label {
    text-align: left;
    display: block;
    font: $font-tiny-bold;
  }
  &-form {
    background-color: #f6f8fc;
    padding: 1rem;
  }

  &-amount {
    display: block;
    font: $font-tiny-bold;
    // height: 100%;
    padding: 1.2rem 1.4rem 1.2rem 1.2rem;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 0;
    color: $primary-text-color;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: #fff;
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;

    &::-ms-expand {
      display: none;
    }
    &:hover {
      border-color: #888;
    }
    &:focus {
      border-color: #aaa;
      box-shadow: 0 0 1px 2px $primary-color;
      box-shadow: 0 0 0 3px -moz-mac-focusing;
      outline: none;
    }
    & option {
      font-weight: normal;
    }

    &--icon {
      position: absolute;
      top: 254.5%;
      left: 88%;
      width: 10px;
    }
  }

  &-form__radio {
    &-group {
      text-align: left;
    }

    &-label {
      color: #7c7c7c;
      font: $font-tiny-bold;
      cursor: pointer;
      position: relative;
      padding-left: 3rem;
    }

    &-button {
      position: absolute;
      display: inline-block;
      height: 2rem;
      width: 2rem;
      border: 2px solid #e6e6e6;
      border-radius: 50%;
      left: 0;
      top: -4px;

      &::after {
        content: "";
        display: block;
        height: 1rem;
        width: 1rem;
        border-radius: 50%;
        position: absolute;
        background-color: $primary-color;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: opacity 0.2s;
      }
    }

    &-input {
      display: none;
    }
    &-input:checked + &-label &-button:after {
      opacity: 1;
    }
  }
}
.loan_payment {
  margin-bottom: 40px;
}
.risk-sub {
  font-size: small;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.risk-sublast {
  font-size: small;
  text-align: center;
  margin-bottom: 2rem;
}

// .service-image > img {
//     height: '50px';
//     @include responsive(phone-port) {
//       height: '20px';
//     }
// }


.title-image {
  background: linear-gradient(
      to right,
      rgba(0, 31, 58, 0.8),
      rgba(0, 31, 58, 0.8)
    ),
    url("../../images/titleperfection.jpg");
  background-size: cover;
  width: 100%;
  height: 300px;
  
}

.legal-image {
  background: linear-gradient(
      to right,
      rgba(0, 31, 58, 0.8),
      rgba(0, 31, 58, 0.8)
    ),
    url("../../images/legalsearch.jpg");
  background-size: cover;
  width: 100%;
  height: 300px;
  
}

