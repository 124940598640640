.modal-overlay {
  z-index: 98;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  transition: .2s;
}

.modal-box {
  z-index: 99;
  position: absolute;
  top: 10%;
  width: 600px;
  min-height: 300px;
  overflow: scroll;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 20px 35px rgba(0, 50, 95, 0.1);
  border-radius: 5px;
  transition: .2s;
  animation-name: animatetop;
  animation-duration: 0.2s;

  @include responsive(tab-port) {
    top: 5%;
  }

  @include responsive(phone-port) {
    // display: flex;
    // flex: 0 0 100%;
    width: 90%;
  }

  img {
      width: 20px;
      float: right;
      margin: 10px;
    //   padding: 1rem;
  }
}

.modal {
  padding: 20px 24px;
  min-height: 500px;
  border-radius: 4px;
  transition: .2s;
}

.modal-message {
  padding: 10px 0px;
  min-height: 300px;
  border-radius: 4px;
  transition: .2s;
}

.modal-message-public {
  padding: 10px 0px;
  min-height: 100px;
  border-radius: 4px;
  transition: .2s;
}

.message-modal__bottom {
  max-width: 80%;
  margin: 0 auto;
  text-align: center;

  h2 {
    font: $font-tiny-semi;
    color: #5D5D5D;

    @include responsive(phone-port) {
      font: $font-small;
    }
  }

  input {
    width: 100%;
    // text-align: center;
    padding: 1rem;
    // font: $font-tiny-semi;
    border: 1px solid #D6D6D6;
    border-radius: 3px;
  }

  button {
    margin: 3rem 10rem;

    &.btn--small {
        @include responsive(phone-port) {
            font: $font-small;
            padding: 0.8rem 6.2rem;
        }
    }
  }
}

.modal-text-top {
  margin-top: 3rem;
}