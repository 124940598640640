.property {
  margin-bottom: 40px;
}

.property-top {
  display: flex;
  align-items: flex-start;

  @include responsive(tab-port) {
    flex-direction: column;
    align-items: unset;
    justify-content: center;
  }
}

.property-public {
  // margin-left: 100px;
  // margin-top: 50px;
  padding: 5rem 10rem; 

  display: flex;
  align-items: space-around;

  @include responsive(tab-port) {
    flex-direction: column;
    align-items: unset;
    justify-content: center;
  }

  @include responsive(phone-port) {
    margin: 2px;
    padding: 1rem 2rem; 
  }
}

.property-img {
  flex: 0 0 50%;
  margin-right: 10rem;
  

  @include responsive(tab-port) {
    margin-right: 0;
  }

  img {
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 1%;
    // height: auto;
    height: 400px;

    @include responsive(tab-port) {
      height: 200px;
    }
    @include responsive(desktop-port) {
      height: 200px;
    }
  }
}


.property-public-img {

  flex: 0 0 50%;
  margin-right: 10rem;
  width: 50%;

  @include responsive(tab-port) {
    margin-right: 0;
  }

  @include responsive(phone-port) {
    margin-bottom: 15px;
    width: 100%;
  }

  img.disp {
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 1%;
    height: 400px;

    @include responsive(tab-port) {
      height: 200px;
    }
    @include responsive(desktop-port) {
      height: 200px;
    }
  }
}

  // img.display-images {
  //   width: 100%;
  //   border: 1px solid #ffffff;
  //   border-radius: 1%;
  //   // height: auto;
  //   height: 400px;

  //   @include responsive(tab-port) {
  //     height: 200px;
  //   }
  //   @include responsive(desktop-port) {
  //     height: 200px;
  //   }
  // }
// }


.property-invest {
  flex: 0 0 40%
}

.property-invest__wrap {
  display: flex;
  width: 100%;

  @include responsive(tab-port) {
    justify-content: space-between;
    margin-top: 1rem;
  }
}

.property-invest__btn {
  display: block;
  width: 47%;
  padding: 0.5rem 2rem;
  margin-bottom: 3rem;
  background-color: #d55b0f;
  color: #fff;
  border: none;
  font: $font-small-semi;
  border-radius: 3px;

  @include responsive(phone-port) {
    padding: 1rem 2rem;
  }
}

.property-invest__btn--watchlist {
  background-color: #e6e6e6;
  color: #7c7c7c;
  margin-left: 2rem;
}

.property-invest__funded {
    &--progress {
        width: 100%;
        height: 7px;
        border-radius: 50px;
        background-color: #dfdfdf;
        margin-right: 2rem;
    }
    margin-bottom: 3rem;

    span {
      color: #656565;
  }
}

.propery-invest__roi {
    h1 {
        font: $font-small-semi;
        text-transform: uppercase;
    }

    p {
        font: $font-tiny;
        color: #656565;
    }
}

.property-bottom {
    margin-top: -5rem;
    width: 70%;

    @include responsive(tab-port) {
      margin-top: 5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }

    &.portfolio-property-bottom {
      margin-top: 10rem;

      @include responsive(phone-port) {
         margin-top: 20rem;
      }
    }
}

.property-public-bottom {
  // margin-top: -5rem;
  width: 100%;
  // margin-left: 100px;
  padding-right: 100px;
  padding-left: 100px;

  @include responsive(tab-port) {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  @include responsive(phone-port) {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 97%;
    padding-right: 15px;
    padding-left: 15px;
    // margin: 10px;
  }
}

.property-name {
    font: $font-medium-semi;
    font-size: 3.4rem;
    color: #DE6C31;
    text-transform: capitalize;
}

.property-name-public {
  font: $font-medium-semi;
  font-size: 2.5rem;
  color: #DE6C31;
  text-transform: capitalize;

  @include responsive(phone-port) {
    font-size: 2.4rem;
  }
}

.property-location {
    margin-top: 2rem;
    font: $font-small;
    margin-bottom: 3rem;

    @include responsive(tab-port) {
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
}

.property-description {
    h1 {
        font: $font-medium-semi;
    }

    p {
        width: 60%;
        font: $font-tiny;

        @include responsive(tab-port) {
          width: 100%;
          font-size: 1.5rem;
          line-height: 2.8rem;
          margin-top: 1rem;
        }
    }
}

.property-desc-public {
  h1 {
    font: $font-medium-semi;
  }

  p {
      width: 60%;
      font: $font-tiny;

      @include responsive(tab-port) {
        width: 100%;
        font-size: 1.5rem;
        line-height: 2.8rem;
        margin-top: 1rem;
      }

      @include responsive(phone-port) {
        width: 97%;
        font-size: 1.5rem;
        line-height: 2.8rem;
        margin-top: 1rem;
      }
  }
}

/**
**** Modal
**/
.invest-modal__top {
  hr {
    border: 1px solid #EAEAEA;
    margin: 3rem 0;
  }
}

.invest-modal-box {
  display: flex;
  align-items: center;

  @include responsive(phone-port) {
   flex-direction: column;
  }
}

.invest-modal-imgbox {
  flex: 0 0 40%;

  @include responsive(phone-port) {
    flex: 0 0 100%;
    margin-top: 1.5rem;
  }

  img {
    width: 100%;
    height: 140px;

    @include responsive(tab-port) {
      float: none;
    }
  }
}

.invest-modal-details {
  flex: 1;
  margin-left: 5rem;

  @include responsive(phone-port) {
    margin-left: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  h1 {
    font: $font-medium-semi;
    color: $primary-color;
    text-transform: capitalize;
    
  }

  h2 {
    font: $font-tiny;
  }
}

.invest-modal-select-box {
  // text-align: left;
}

.investment-total-payable {
  margin-top: 3rem;
}

.invest-modal-select {
  border: 1px solid #D6D6D6;
  width: 50%;
  margin: 0 auto;
  text-align: center;
  padding: .5rem;
  padding-left: 6rem;
 font: $font-tiny-semi;
    color: #5D5D5D;
  border-radius: 0;



  @include responsive(phone-port) {
    font: $font-small;
    width: 95%;
    padding-left: 9rem;
  }

  option {
    height: 50px;
    overflow: scroll;
  }
}

.invest-modal-roi {
  margin-top: 3rem;

  @include responsive(phone-port) {
    margin-top: 0;
  }

  h1 {
    font: $font-tiny;
    color: inherit;
    text-transform: uppercase;
  }

  h2 {
    font: $font-tiny-bold;
  }
}

.invest-modal__bottom {
  max-width: 50%;
  margin: 0 auto;
  text-align: center;

  

  h2 {
    font: $font-tiny-semi;
    color: #5D5D5D;

    @include responsive(phone-port) {
      font: $font-small;
    }
  }

  input {
    width: 100%;
    text-align: center;
    padding: .5rem;
    font: $font-tiny-semi;
    border: 1px solid #D6D6D6;
    border-radius: 3px;
  }

  button {
    margin: 3rem 0;

    &.btn--small {
        @include responsive(phone-port) {
            font: $font-small;
            padding: 0.8rem 6.2rem;
        }
    }
  }
}

.invest-modal__input {
  &:not(:last-child) {
      margin-bottom: 2rem;
    }
}

.invest-modal-grid  {
  display: flex;

  input {
    &:first-child {
      width: 40px;
      margin-right: 5px;
    }
    &:nth-child(2) {
      width: 40px;
    }
    
    &:last-child {
      margin-left: 3rem;
    }
  }
}

.invest-modal__success {
  display: block;
  &--img {
    width: 120px;
    margin: 0 auto;
    text-align: center;

    img {
      width: 100%;
    }
    // display: block;
  }

  h1 {
    display: inline-block;
    font: $font-medium-semi;
    margin: 1rem 0;
  }

  h2 {
    font: $font-tiny-semi;
  }

  span {
    color: #D55C0F;
  }

}

.invest-modal__input-radio {
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    width: 10%;
  }

  img {
    width: 100px;
  }
}

.portfolio-property__btn  {
  width: 100%;
}