.rent-details {
  // flex: 0 0 50%
  width: 100%;
  @include responsive(phone-port) {
    // justify-content: space-between;
    width: 100%;
    margin-top: 1rem;
  }
}

.download {
  display: flex;
  justify-content: space-around;

  @include responsive(tab-port) {
    flex-direction: column;
    justify-content: center;
  }

  @include responsive(phone-port) {
    flex-direction: column;
    justify-content: center;
  }
}

.download-inputs {
  display: flex;
  justify-content: space-between;

  @include responsive(tab-port) {
    flex-direction: column;
    justify-content: center;
  }

  @include responsive(phone-port) {
    flex-direction: column;
    justify-content: center;
  }
}

.download-button {
  background-color: #DF6F12; 
  border: none; 
  color: #00325f; padding: 10px 10px; 
  text-decoration: none; 
  // text-transform: uppercase; 
  display: inline-block; 
  // font-weight: 600; 
  border-radius: 3px; 
  margin: 10px 10px; 
  cursor: pointer;
  width: 90%;
  text-align: center;

  // @include responsive(phone-port) {
  //   margin: 10px 10px; 
  // }
}

.download-report {
  background-color: #DF6F12; 
  border: none; 
  color: #fff; 
  text-decoration: none; 
  display: inline-block; 
  border-radius: 3px; 
  // margin: 10px 10px; 
  cursor: pointer;
  width: 10%;
  text-align: center;
}

.download-supporting-report {
  background-color: #DF6F12; 
  border: none; 
  color: #fff; 
  text-decoration: none; 
  display: inline-block; 
  border-radius: 3px; 
  // margin: 10px 10px; 
  cursor: pointer;
  width: 20%;
  text-align: center;
}

.rent-doc {
  color: $primary-text-color;
  max-width: 60.7rem;
  margin: 4rem 0 2rem 0;
  font: $font-custom-heading;
  // margin-top: 30px;
}

.rent {
  &__text {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  &__input {
    background: #ffffff;
    margin-left: 6rem;
    margin-right: 6rem;

  }
  margin-left: 10rem;
  margin-right: 20rem;
}

.rent-button {
  margin-top: 2rem;
  margin-bottom: 2rem;

  &__submit {
    margin-top: 0rem;
    margin-bottom: 0rem;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  &__loader {
    padding: 2rem;
  }
}

.rent-input {
  margin-top: 20px;
  &-label {
    text-align: left;
    display: block;
    font: $font-tiny-bold;
  }
  &-form {
    background-color: #ffffff;
    padding: 1rem;
  }
}


// style={{margin: '20px', textAlign: 'center'}}
// style={{margin: '20px', textAlign: 'center'}}
// style={{margin: '20px', textAlign: 'center'}}


.rent-grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto auto;
  background-color: #fff;
  padding: 10px;
}
.rent-grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 10px;
  // font-size: 30px;
  text-align: center;
}