// i.arrow-down {
//     border: solid #ffffff;
//     border-width: 0 2px 2px 0;
//     display: inline-block;
//     padding: 2px;
    
// }

span .checkDrop > i {
  border: solid #D55B0F;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
}

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-left: 10px;
}

// .arrow-r {
//   border: solid #ffffff;
//   border-width: 0 2px 2px 0;
//   display: inline-block;
//   padding: 2px;
// }


.arrow-r {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-left: 10px;
}

.arrow-d {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-left: 10px;
}