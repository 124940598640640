.dropdown {
    ul {
        position: absolute;
        background:  rgba(0, 0, 0, 0.3);
        // background:  #fff;
        padding: 20px 20px;
        // border-radius: 2px;
        width: 40%;
        min-height: 5rem;
        text-align: left;
        // overflow: scroll;
        // margin-left: -10px;

        @include responsive(tab-port) {
            position: relative;
            width: auto;
            margin-left: 0;
            background:  inherit;
            padding-top: 0;
        //  background-color: #fff;
        }

        .dropdown-list {
            text-decoration: none;
            border: none;
            // border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            // margin-bottom: 3px;
            display: block;
            width: 100%;
            text-align: center;
            background: transparent;
            color: rgba(255, 255, 255, 0.5);
            cursor: pointer;
            font: $font-tiny;

            &:hover,
            &:focus {
                color: $primary-color;
                border-color: $primary-color;
            }

            @include responsive(tab-port) {
                text-align: left;
                color: #fff;
            }

            @include responsive(phone-port) {
             font-size: 10px;
          }
        }
    }

    ul.others {
      position: absolute;
      background:  rgba(0, 0, 0, 0.3);
      // background:  #fff;
      padding: 20px 20px;
      // border-radius: 2px;
      width: 16%;
      min-height: 5rem;
      text-align: left;
      // overflow: scroll;
      // margin-left: -10px;

      @include responsive(tab-port) {
          position: relative;
          width: auto;
          margin-left: 0;
          background:  inherit;
          padding-top: 0;
      //  background-color: #fff;
      }

      .dropdown-list {
          text-decoration: none;
          border: none;
          // border-bottom: 1px solid rgba(255, 255, 255, 0.3);
          // margin-bottom: 3px;
          display: block;
          width: 100%;
          // text-align: center;
          background: transparent;
          color: rgba(255, 255, 255, 0.5);
          cursor: pointer;
          font: $font-tiny;

          &:hover,
          &:focus {
              color: $primary-color;
              border-color: $primary-color;
          }

          @include responsive(tab-port) {
              text-align: left;
              color: #fff;
          }
          @include responsive(phone-port) {
           font-size: 10px;
        }
      }
  }
}



.dropdown-auth {
  ul {
      position: absolute;
      background:  rgba(0, 0, 0, 0.3);
      padding: 20px 20px;
      min-height: 5rem;
      text-align: left;
      margin-left: -20px;
      // margin-top: 5px;

      @include responsive(tab-port) {
          position: relative;
          width: auto;
          margin-left: 0;
          background:  inherit;
          padding-top: 0;
      }

      .dropdown-list {
          text-decoration: none;
          border: none;
          display: block;
          width: 100%;
          text-align: center;
          background: transparent;
          color: rgba(255, 255, 255, 0.5);
          cursor: pointer;
          font: $font-tiny;

          &:hover,
          &:focus {
              color: $primary-color;
              border-color: $primary-color;
          }

          @include responsive(tab-port) {
              text-align: left;
              color: #fff;
          }

          @include responsive(phone-port) {
           font-size: 10px;
        }
      }
  }
}

.style-dropdown {
  display: none;
}

.style-dropdown li {
  z-index: 999;
  background: #f2f2f2;
  display: block;
  margin-right: 20px;
}

.dropdown:hover .style-dropdown {
  z-index: 999;
  display: flex;
  // margin-left: 30px;
  // display: block;
  justify-content: space-around;
}

.dropdown-auth:hover .style-dropdown {
  // display: flex;
  // margin-left: 30px;
  display: block;
  // justify-content: space-around;
}


// .dropdown-auth .style-dropdown {
//   // margin-left: 30px;
//   display: block;
// }


// .accordion__section {
//   display: flex;
//   flex-direction: column;
// }

// /* Style the buttons that are used to open and close the accordion panel */
// .accordion {
//   background-color: #eee;
//   color: #444;
//   cursor: pointer;
//   padding: 18px;
//   display: flex;
//   align-items: center;
//   border: none;
//   outline: none;
//   transition: background-color 0.6s ease;
// }

// /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
// .accordion:hover,
// .active {
//   background-color: #ccc;
// }

// /* Style the accordion content title */
// .accordion__title {
//   font-family: "Open Sans", sans-serif;
//   font-weight: 600;
//   font-size: 14px;
//   text-align: left;
// }

// /* Style the accordion chevron icon */
// .accordion__icon {
//   margin-left: auto;
//   transition: transform 0.6s ease;
// }

// /* Style to rotate icon when state is active */
// .rotate {
//   transform: rotate(90deg);
// }

// /* Style the accordion content panel. Note: hidden by default */
// .accordion__content {
//   background-color: white;
//   overflow: auto;
//   transition: max-height 0.6s ease;
// }

// /* Style the accordion content text */
// .accordion__text {
//   font-family: "Open Sans", sans-serif;
//   font-weight: 400;
//   font-size: 14px;
//   padding: 18px;
//   color: #000;
// }


@media (max-width: 768px) {
  .header-desktop {
    display: none;
  }
  .header-mobile {
    width: 100%;
    display: block;
  }
}

@media (min-width: 768px) {
  .header-mobile {
    display: none;
  }
  .header-desktop {
    display: block;
  }
}

.avatar {
  position: relative;
  top: 5.5px;
  height: 40px;

  @include responsive(phone-port) {
    height: 20px;

    // position: relative;
    // width: auto;
    // margin-left: 0;
    // background:  inherit;
    // padding-top: 0;
  }
}
.style-nav-logout {
  // background: transparent;
  margin-top: 5px;
  text-decoration: none;
  border: none;
  display: block;
  width: 9%;
  text-align: center;
  background: transparent;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  font: normal 300 1.2rem/2.6rem "Graphik";
}

.style-nav-logout:hover {
  // background: transparent;
  color: #D55B0F;
}
