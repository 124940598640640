.investor-overview {
    &-heading {
        font: $font-small;
        font-weight: 400;
        padding: 2rem;
        border-bottom: 2px solid #eaeaea81;
    }

    &-admin {
      font: $font-small;
      font-weight: 400;
      padding-bottom: 2rem;
      border-bottom: 2px solid #eaeaea81;
  }

    margin-bottom: 3rem;
}

.investor-overview__top {
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
}

.investor-overview__top-box {
    display: flex;
    min-height: 30rem;

    @include responsive(tab-port) {
        flex-direction: column;
        overflow: initial;
    }
}

.investor-overview--portfolio {
    // flex: 0 0 65%;
    width: 100%;
    padding-left: 2rem;
    padding-top: 3rem;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 3rem;
    padding-right: 2rem;

    @include responsive(tab-port) {
        padding-bottom: 3rem;
        margin-bottom: 1rem;
        border-bottom: 2px solid #eaeaea81;
    }
}

.investor-overview--investments {
    flex: 1;
}

.investor-overview-details {
    flex: 1 0 50%;
    padding: 1rem;
    // padding-left: 3rem;
    text-align: center;

    @include responsive(phone-port) {
        padding-left: 1rem;
        text-align: center;
    }
    
    h3 {
        font: $font-small-semi;
        font-size: 1.2rem;
        color: #6E6E6E;
        text-transform: capitalize;

        @include responsive(phone-port) {
            font-size: 1.5rem;
        }
    }

    h1 {
        font: $font-medium-bold;
        font-size: 3.6rem;
        font-weight: 400;
        letter-spacing: .2rem;
    }

    &:first-child {
        border-right: 2px solid #eaeaea81;
        border-bottom: 2px solid #eaeaea81;
    }

    &:nth-child(2) {
        border-bottom: 2px solid #eaeaea81;
    }

    &:nth-child(3) {
        border-right: 2px solid #eaeaea81;
    }
}


.investor-overview-admin-details {
  flex: 1 0 50%;
  padding: 1rem;
  // padding-left: 3rem;
  text-align: center;

  @include responsive(phone-port) {
      padding-left: 1rem;
      text-align: center;
  }
  
  h3 {
      font: $font-small-semi;
      font-size: 1.2rem;
      color: #6E6E6E;
      text-transform: capitalize;

      @include responsive(phone-port) {
          font-size: 1.5rem;
      }
  }

  h1 {
      font: $font-medium-bold;
      font-size: 3.6rem;
      font-weight: 400;
      letter-spacing: .2rem;
  }


  border-right: 2px solid #eaeaea81;


  &:first-child {
      border-right: 2px solid #eaeaea81;
      border-bottom: 2px solid #eaeaea81;
      border-top: 2px solid #eaeaea81;
      border-left: 2px solid #eaeaea81;
  }

  &:nth-child(2) {
      // border-bottom: 2px solid #eaeaea81;

      border-right: 2px solid #eaeaea81;
      border-bottom: 2px solid #eaeaea81;
      border-top: 2px solid #eaeaea81;
      border-left: 2px solid #eaeaea81;
  }

  &:nth-child(3) {
      // border-right: 2px solid #eaeaea81;

      border-right: 2px solid #eaeaea81;
      border-bottom: 2px solid #eaeaea81;
      border-top: 2px solid #eaeaea81;
      border-left: 2px solid #eaeaea81;
  }

  &:nth-child(4) {
    // border-right: 2px solid #eaeaea81;
    border-right: 2px solid #eaeaea81;
    border-bottom: 2px solid #eaeaea81;
    border-top: 2px solid #eaeaea81;
    border-left: 2px solid #eaeaea81;
}
&:nth-child(5) {
  // border-right: 2px solid #eaeaea81;
  border-right: 2px solid #eaeaea81;
  border-bottom: 2px solid #eaeaea81;
  border-top: 2px solid #eaeaea81;
  border-left: 2px solid #eaeaea81;
}
&:nth-child(6) {
  // border-right: 2px solid #eaeaea81;
  border-right: 2px solid #eaeaea81;
  border-bottom: 2px solid #eaeaea81;
  border-top: 2px solid #eaeaea81;
  border-left: 2px solid #eaeaea81;
}
// &:nth-child(7) {
//   // border-right: 2px solid #eaeaea81;
//   border-right: 2px solid #eaeaea81;
//   border-bottom: 2px solid #eaeaea81;
//   border-top: 2px solid #eaeaea81;
//   border-left: 2px solid #eaeaea81;
// }
// &:nth-child(8) {
//   // border-right: 2px solid #eaeaea81;
//   border-right: 2px solid #eaeaea81;
//   border-bottom: 2px solid #eaeaea81;
//   border-top: 2px solid #eaeaea81;
//   border-left: 2px solid #eaeaea81;
// }


  // border-right: 2px solid #eaeaea81;

}


.investor-overview--investments-box {
    display: flex;
    margin-bottom: 2rem;
    color: #656565;
}

.investor-overview--investments-investors {
  display: flex;
  justify-content: space-around;
  margin-bottom: 2rem;
  color: #656565;
}

.investment-overview__imgbox {
    margin-right: 2rem;
}

.investor-overview-progress {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.investor-overview-progressbar {
   margin-right: 1rem;
}

.investor-overview--investments {
    border-left: 2px solid #eaeaea81;
    padding: 1rem;

    @include responsive(tab-port) {
        padding: 1rem 2rem;
    }

    h2 {
        font: $font-small-semi;
        margin-bottom: 1rem;
    }
    
}

.investment-overview--img {
    width: 50px;
    height: 44px;
    border-radius: 5px;
}

.investor-overview__detailsbox {
    margin-right: 1rem;
    padding-right: 1rem;
    border-right: 2px solid #eaeaea81;
    h3 {
        font: $font-tiny-semi;
        color: #656565;
    }
}

.investor-overview__roi {
    span {
        font: $font-tiny-semi;
    }
}

.investor-overview-roi {
    font: $font-tiny-semi !important;
    margin-bottom: 0 !important;
}

.investor-overview__bottom {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    margin-top: 5rem;

    @include responsive(tab-port) {
        flex-direction: row;

        margin-top: 2rem;
    }

    @include responsive(phone-port) {
        flex-direction: column;
        margin-top: 2rem;
    }
}



.investor-overview__card {
    border-radius: 5px;
    background-color: #F9FBFE;
    min-width: 40rem;
    height: 40rem;
    // flex: 0 0 40rem;

    @include responsive(tab-port) {
        min-width: 0;
        flex: 0 0 37.6rem;
    }

    &--header {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        background-color: #fff;
        padding: 2rem 2rem;
        font: $font-small-semi;
    }

    &--body {
        padding: 2rem 2rem;
        // overflow: scroll;
        // width:600px; 
        overflow-y:scroll; 
        position:relative;
        height: 300px;
    }

    &:not(:first-child) {
        @include responsive(phone-port) {
            margin-top: 2rem;
        }
    }
}

