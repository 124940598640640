.header-nav {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;

     @include responsive(tab-port) {
      align-items: center;
    }

    &-2 {
      background-color: #fff;
      color: $primary-text-color;

      .dropdown {
        button {
          color: $primary-text-color;

           @include responsive(tab-port) {
            color: #fff;
           }
        }
       
      }
    }

    &.profile-nav {
      padding-left: 2rem;
      padding-right: 2rem;
      max-width: 100%;

      img {
        // width: 30% !important;
        padding-top: 10px;
      }
    }
}

.header-logo {
    max-width: 50px;
    font: $font-small-semi;
    flex:  0 0 20%;
    color: #fff;
    // display: flex;

    &--text {
      font: $font-small-semi;
    }
}

.logo {
  width: 100px;
  height: auto;
  font-size: 200px;
  // height: 100px;

  @include responsive(tab-port) {
    width: 30px;
    // height: 100px
  }
}

.header-section-wrap{
  @include responsive(tab-port) {
    margin-right: 0 !important;
    max-width: calc(100% - 15px) !important;
  }
}

.header-navbar {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font: $font-tiny-semi !important;

    @include responsive(tab-port) {
      display: none;
      overflow-y: scroll;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 30;
      flex-direction: column;
      height:100%;
      width: 300px;
      align-items: flex-start;
      justify-content: flex-start;
      padding-top: 10rem;
      margin-right: 0;
      background-color: $primary-btn-color;
      color: #fff !important;
    }

    @include responsive(phone-port) {
      display: none;
      position: absolute;
      overflow-y: scroll;
      right: 0;
      top: 0;
      z-index: 30;
      // flex-direction: column;
      height:100%;
      width: 100%;
      padding: 2rem 4rem;
      // align-items: center;
      // justify-content: flex-start;
      padding-top: 10rem;
      margin-right: 0;
      margin-left: 40px;
      background-color: $primary-btn-color;
      color: #fff !important;
    }

    &__services {
        display: flex;
        align-items: center;
        margin-right: 1rem;

        @include responsive(tab-port) {
          margin-right: 0;
          justify-content: space-between;
          margin-bottom: 3rem;
          font: $font-small-semi !important;
        }
    }

    &__aboutus {
        margin-right: 1rem;
        margin-left: 0 !important;

        @include responsive(tab-port) {
          text-align: left;
          margin-right: 0;
          margin-bottom: 3rem;
          font: $font-small-semi;
          color: #fff !important;
        }
    }
}

.header-navbar__btn {
    margin-left: 25px;
    
     @include responsive(tab-port) {
          margin-left: 0;
     }
}

.nav-btn {
  @include responsive(tab-port) {
    background-color: inherit !important;
    border: none !important;
    color: #fff;
    text-align: left !important;
    // margin-top: 1.5rem;
    margin-bottom: 3rem;
    font: $font-small-semi;
  }
}

.hambuger-close {
  display: none;
  position: absolute;
  width: 15px;
  right: 5%;
  top: 2%;
  @include responsive(tab-port) {
    display: block;
  }
}

.hambuger-nav {
   @include responsive(tab-port) {
    display: flex !important;
    transition: .3s;
  }
 
  button {
    order: -1;
  }
}

.hambuger {
  display: none;
  @include responsive(tab-port) {
    display: block;
    margin-right: 2rem;
    width: 20px;
  }
  
}

.trade {
  height: 100px;

  @include responsive(phone-port) {
    height: 50px;
  }
}