.loader-wrapper {
  position: fixed;      
  top: 0;
  left: 0;
  width: 100vw;         /* USE VIEWPORT UNITS */ 
  height: 100vh;        /* USE VIEWPORT UNITS */
  z-index: 1001;        /* MOVE Z-INDEX HERE! */
}
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid $primary-color;
  margin: -7vmax;
  // border-bottom: 16px solid $primary-text-color;
  width: 150px;
  height: 150px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loadertiny {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid $primary-color;
  margin: -1vmax;
  // border-bottom: 16px solid $primary-text-color;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
}


.loadercustom {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid $primary-color;
  margin: -1vmax;
  // border-bottom: 16px solid $primary-text-color;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
}