.header {
  min-height: 100vh;
  background-color: black;

  @include responsive(tab-port) {
    min-height: 0;
    height: 60vh;
  }
  &-home {
    background: linear-gradient(
        to right,
        rgba(0, 31, 58, 0.8),
        rgba(0, 31, 58, 0.8)
      ),
      url("../../images/Homepage/HomePage.png");
    background-size: cover;
    width: 100%;

    button {
      display: inline-block;
    }
  }

  &-faq {
    background: linear-gradient(
        to right,
        rgba(0, 31, 58, 0.8),
        rgba(0, 31, 58, 0.8)
      ),
      url("../../images/FAQ/faq.png");
    background-size: cover;
    width: 100%;
  }

  &-risks {
    background: linear-gradient(
        to right,
        rgba(0, 31, 58, 0.8),
        rgba(0, 31, 58, 0.8)
      ),
      url("../../images/risk.jpeg");
    background-size: cover;
    background-position: center;
    background-position-x: -50px;

    @include responsive(phone-port) {
      background-position-x: -180px;
    }

    .header__content-box {
      margin-top: 50rem;
      @include responsive(phone-port) {
        margin-top: 5rem;
      }
    }

    width: 100%;
  }

  &-market {
    background: linear-gradient(
        to right,
        rgba(0, 31, 58, 0.8),
        rgba(0, 31, 58, 0.8)
      ),
      url("../../images/Market/market.png");
    background-size: cover;
    width: 100%;

    button {
      display: inline-block;
    }
  }

  &-about {
    background: linear-gradient(
        to right,
        rgba(0, 31, 58, 0.8),
        rgba(0, 31, 58, 0.8)
      ),
      url("../../images/aboutpage/aboutus.png");
    background-size: cover;
    width: 100%;

    button {
      display: inline-block;
    }

    p {
      margin: 2rem 0;
      color: #c3c3c3;
    }
  }

  &-properties {
    background: linear-gradient(
        to right,
        rgba(0, 31, 58, 0.8),
        rgba(0, 31, 58, 0.8)
      ),
      url("../../images/propertysearch.jpg");
    background-size: cover;
    width: 100%;

    button {
      display: inline-block;
    }
    p {
      margin: 2rem 0;
      color: #c3c3c3;
    }
  }

  &-investorpage {
    background: linear-gradient(
        to right,
        rgba(0, 31, 58, 0.8),
        rgba(0, 31, 58, 0.8)
      ),
      url("../../images/ThePlatforms/investor.jpg");
    background-size: cover;
    width: 100%;

    button {
      display: inline-block;
    }

    p {
      margin: 2rem 0;
      color: #c3c3c3;
    }
  }

  &-assetownerpage {
    background: linear-gradient(
        to right,
        rgba(0, 31, 58, 0.8),
        rgba(0, 31, 58, 0.8)
      ),
      url("../../images/ThePlatforms/assetowner.jpg");
    background-size: cover;
    width: 100%;

    button {
      display: inline-block;
    }

    p {
      margin: 2rem 0;
      color: #c3c3c3;
    }
  }

  &-developerpage {
    background: linear-gradient(
        to right,
        rgba(0, 31, 58, 0.8),
        rgba(0, 31, 58, 0.8)
      ),
      url("../../images/ThePlatforms/developer.jpg");
    background-size: cover;
    width: 100%;

    button {
      display: inline-block;
    }

    p {
      margin: 2rem 0;
      color: #c3c3c3;
    }
  }

  &-easyrentpage {
    background: linear-gradient(
        to right,
        rgba(0, 31, 58, 0.8),
        rgba(0, 31, 58, 0.8)
      ),
      url("../../images/ThePlatforms/easyrent.jpg");
    background-size: cover;
    width: 100%;

    button {
      display: inline-block;
    }

    p {
      margin: 2rem 0;
      color: #c3c3c3;
    }
  }

  &-dsrpage {
    background: linear-gradient(
        to right,
        rgba(0, 31, 58, 0.8),
        rgba(0, 31, 58, 0.8)
      ),
      url("../../images/ThePlatforms/dsr.jpg");
    background-size: cover;
    width: 100%;

    button {
      display: inline-block;
    }

    p {
      margin: 2rem 0;
      color: #c3c3c3;
    }
  }

  &-distressedpage {
    background: linear-gradient(
        to right,
        rgba(0, 31, 58, 0.8),
        rgba(0, 31, 58, 0.8)
      ),
      url("../../images/ThePlatforms/distressedsale.png");
    background-size: cover;
    width: 100%;

    button {
      display: inline-block;
    }

    p {
      margin: 2rem 0;
      color: #c3c3c3;
    }
  }

  &__content {
    max-width: 67rem;
    margin: 0 auto;
    padding-bottom: 10rem;
    // position: absolute;
    // top: 65%;
    // left: 50%;
    // transform: translate(-50%, -65%) ;
  }

  &__content-inv {
    // max-width: 67rem;
    margin-right: 0 20px;
    padding-bottom: 10rem;
    // text-align: right;
    // position: absolute;
    // top: 65%;
    // left: 50%;
    // transform: translate(-50%, -65%) ;
  }

  &__content-box {
    margin-top: 217px;
    color: #fff;

    @include responsive(tab-port) {
      margin-top: 10rem;
    }
  }

  &__img-box {
    position: absolute;
    top: 195px;
    right: 0;

    &--home {
      position: relative;
      top: -50rem;
      right: -600px;
    }
  }

  &__img {
    height: 768px;
    max-width: 100%;

    &--reduced {
      height: 509px;
      max-width: 100%;
    }
  }
}
.header-all {
  
}

.header-wrap {
  padding-left: 14rem;
  background-image: $bckgd-linear-gradient;
}

.header__homepage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
}

.header__text {
  flex: 0 0 50%;
  margin-top: -15rem;
  color: #fff;

  &--primary {
    font: $font-medium-bold;
    margin-bottom: 4.5rem;
    color: #fff;
  }
  &--secondary {
    font: $font-tiny;
    color: #fff;
  }
}


.header-imagebox {
  max-height: 1108px;
}

.register-form {

  &__code {
    flex: 0 0 10% !important;
    position: relative;

    label {
      width: 100%;
      left: 0;
      text-align: left;
    }

    @include responsive(phone-port) {
      flex: 0 0 30% !important;
      // margin-right: 0 !important;
    }
  }

  .register-form-box {
    flex: 1;
    text-align: left;
    width: 97%;

    @include responsive(phone-port) {
      flex: 0 0 100%;
      margin-right: 0 !important;
    }

    &.register-form-box-phone {
      flex: 0 0 63%;
      float: right;
    }
  }

  .register-age {
    @include responsive(phone-port) {
      flex: 0 0 100%;
      margin-right: 0 !important;
    }
  }


  &__input {
    width: 100%;
    padding: 1.5rem 1.4rem;
    border: none;
    font: $font-tiny-bold;
    font-size: 1.4rem;
    outline: none;
    color: $primary-text-color;
    border: 2px solid transparent;
    display: block;
    border-radius: 5px;
    transition: all 0.3s;

    &::-webkit-input-placeholder {
      color: #8e8e8e;
      font: $font-tiny;
      text-transform: capitalize;
    }

    &:focus {
      box-shadow: 0 1rem 2rem rgba($color: #000000, $alpha: 0.1);
      border: 2px solid $primary-btn-color;
    }

    &:focus:invalid {
      border: 2px solid $primary-color;
    }

    &--address {
      padding-bottom: 8rem;
      word-wrap: break-word;
    }
  }

  &__input-border {
    width: 100%;
    padding: 1.5rem 1.4rem;
    font: $font-tiny-bold;
    font-size: 1.4rem;
    outline: none;
    color: $primary-text-color;
    border: 1px solid #D6D6D6;
    display: block;
    border-radius: 5px;
    transition: all 0.3s;

    &::-webkit-input-placeholder {
      color: #8e8e8e;
      font: $font-tiny;
      text-transform: capitalize;
    }

    &:focus {
      box-shadow: 0 1rem 2rem rgba($color: #000000, $alpha: 0.1);
      border: 2px solid $primary-btn-color;
    }

    &:focus:invalid {
      border: 2px solid $primary-color;
    }

    &--address {
      padding-bottom: 8rem;
      word-wrap: break-word;
    }
  }

  &__label {
    position: absolute;
    padding-left: 1.4rem;
    margin-top: 0.5rem;
    font: $font-tiny;
    font-size: 1rem;
    text-transform: capitalize;
    color: #8e8e8e;
    transition: all 0.3s;
    transform: translateY(-6.5rem);
  }

  &__input:placeholder-shown + &__label {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-4rem);
  }

  &__input--address + &__label {
    transform: translateY(-13rem);
  }

  input[type="date"] {
    &::-webkit-datetime-edit-fields-wrapper {
      color: $primary-text-color;
      font: $font-tiny-bold;
    }
    &::-webkit-inner-spin-button {
      display: none;
    }
  }
}