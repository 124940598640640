.section-staticfooter {
  background-color: $primary-white-color;
  max-width: $grid-full-width;
}

.section-partners {
  &__heading {
    font: $font-tiny-bold;
    text-transform: uppercase;
  }
}

.section-grow {
  background-color: rgba(255, 172, 121, 0.3);
  padding: 8rem 7rem;

  @include responsive(tab-port) {
    padding: 10rem 4rem;
  }

  @include responsive(phone-port) {
    padding: 6rem 2rem;
  }

  &__grid {
    //  display: flex;
    align-items: center;
    justify-content: center;

    .col-1-of-2 {
      text-align: center;
      // flex: 1 0 100%!important;
    }
  }

  &__img {
    max-width: 36rem;
  }

  &__heading {
    color: $primary-text-color;
    margin-bottom: 3rem;

    @include responsive(tab-port) {
      margin-bottom: 2rem;
    }
  }

  &-text--sub {
    color: $primary-text-color;
    margin-bottom: 30px;
  }
}

.section-footer {
  &__heading {
    font: $font-custom-heading;
    color: $primary-text-color;
    text-transform: lowercase;
  }

  &__grid {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 12rem;
  }

  h2 {
    color: hsl(0, 0%, 39%);
  }

  padding-bottom: 80px;

  hr {
    background-color: rgba(0, 50, 95, 0.5);
    height: 0.5px;
    border: 0;
  }

  .col {
    @include responsive(tab-port) {
      flex-direction: column;
    }
  }

  .col-1-of-4 {
    @include responsive(tab-port) {
      text-align: center;
    }
  }
}

.footer-heading {
  font: normal 500 1.2rem/2.6rem Graphik;
  color: #004864;
  margin-bottom: 36px;
  text-align: left;

  @include responsive(tab-port) {
    margin-bottom: 10px;
  }

  @include responsive(phone-port) {
    text-align: center;
  }
}

.footer-links {
  font: $font-tiny-semi;
  font-size: 1.3rem;
  display: block;
  color: #6e6e6e;
  width: 100%;
  text-align: left;
  font-weight: 300;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.section-footer__grid {
  a {
    margin-bottom: 1.5rem;
  }

  @include responsive(tab-port) {
    text-align: center !important;
    margin-left: 0;
  }

  @include responsive(phone-port) {
    flex-direction: column;
    align-items: center;

    button {
      text-align: center !important;
    }
  }

  .col-1-of-4 {
    @include responsive(phone-port) {
      margin-right: 0 !important;
    }
  }
}
