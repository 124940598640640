.investor-img-left {
  flex: 0 0 25%;
  @include responsive(phone-port) {
    flex: 0 0 10%;
  }
}

.investor-text-right {
  flex: 1 1;
  margin-left: 5rem;
}

.investor-page-box {
  display: flex;
  align-items: center;
  border-bottom: #d55b0f 10px solid;

  @include responsive(phone-port) {
    flex-direction: column;
  }

}

.investor-img-text {
  text-align: center;
}

.scenario-img {
  @include responsive(phone-port) {
   width: 170px;
   height: 170px;
  }
}