.section {
  &:not(:last-child) {
    margin-bottom: $section-gap;
    // margin-top: $section-gap;

  }
}

.section-wrap {
  max-width: $grid-width;
  margin: 0 auto;

  @include responsive(tab-land) {
    max-width: $grid-width-tab-land;
  }

  @include responsive(phone-port) {
    max-width: $grid-width-phone;
  }
}

.grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.col-second-child {
  margin-right: 0 !important;
}

.col {
  display: flex;
  flex-wrap: wrap;

  &:not(:last-child) {
    margin-bottom: $gutter-vertical;
  }

  &:last-child {
    flex: 1;
  }

  [class^="col-"] {
    margin-bottom: $gutter-horizontal;
    &:not(:last-child) {
      margin-right: $gutter-horizontal;
    }
  }

  .col-1-of-2 {
    // width: calc((100% - #{$gutter-horizontal}) / 2 );
    flex: 1 0 47%;

    &:nth-child(2) {
      margin-right: 0;
    }
  }

  .col-1-of-3 {
    // width: calc((100% - 2 * #{$gutter-horizontal}) / 3);
    flex: 1 0 30.3%;

    &:nth-child(3),
    &:nth-child(6) {
      margin-right: 0;
    }
  }

  .col-2-of-3 {
    flex: 1 0 62%;
    // width: calc(( 2 * (100% - 2 * #{$gutter-horizontal}) / 3) + #{$gutter-horizontal});
  }

  .col-1-of-4 {
    // width: calc((100% - 3 * #{$gutter-horizontal}) / 4);
    flex: 1 0 23%;
  }

  .col-2-of-4 {
    flex: 1 0 46%;
    width: calc(
      2 * ((100% - 3 * #{$gutter-horizontal}) / 4) + #{$gutter-horizontal}
    );
  }

  .col-3-of-4 {
    width: calc(
      3 * ((100% - 3 * #{$gutter-horizontal}) / 4) + 2 * #{$gutter-horizontal}
    );
  }

  .col-3-of-3 {
    flex: 1 0 31%;
  }
}

// .container {
//   display: flex;
//   flex-wrap: wrap;

//   .flex1 {
//     flex-basis: 200px;
//     background-color: red;
//     margin-right: 20px;
//   }
// }
