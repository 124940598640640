.btn {
    color: #fff;
    position: relative;
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin-left: 2px; //allow for accessibility

    &--small {
        font: $font-tiny-bold;
        padding: 0.4rem 2.2rem;
    }

    &--action {
      font: $font-tiny-bold;
      padding: 0.4rem 4.2rem;
    }

    &--small_admin {
      font: $font-tiny-bold;
      padding: 0rem 1.0rem;
    }
    &--smaller {
      font: $font-tiny-bold;
      padding: 0.5rem 0.5rem;
    }

    &--big {
        font: $font-tiny-bold;
        padding: 0.7rem 3.9rem;
        font-weight: 500;
    }
    &--search_big {
      font: $font-tiny-bold;
      padding: 1.7rem 3.9rem;
      font-weight: 700;
    }
    &--search_top {
      font: $font-tiny-bold;
      padding: 1.5rem 3.9rem;
      font-weight: 700;
    }

    &--orange {
        border: 1px solid #f79f1b;
        background-color: $primary-btn-bckgd;
    }
    &--blue {
      border: 1px solid #f79f1b;
      background-color: blueviolet;
    }
    &--gray {
      border: 1px solid gray;
      background-color: $primary-btn-bckgd;
  }

    &--white {
        background-color: $primary-btn-bckgd;
    }

    &--round {
        border-radius: 3px;
    }

    &--shadow {
        box-shadow: 0 10px 20px rgba(252, 199, 119, 0.46);
    }

    &--uppercase {
        text-transform: uppercase;
    }

    &--border {
        border: 1px solid #e7e7e7;
    }

    &--hover:hover {
        background-color: $primary-btn-bckgd;
        border: 1px solid $primary-btn-bckgd;
    }
    &--focus:focus {
        background-color: $primary-dark-color;
        border: 1px solid $primary-btn-bckgd;
    }

    &--hide {
      display: none;
    }

    &--black {
      color: $primary-text-color;
    }

    &--gray {
      background-color:  #cecece;
      cursor: not-allowed;
    }

    &--word {
      color: #D55B0F;
      border: none;
      border-bottom: 1px solid #D55B0F;
    }

    &--disabled {
        background-color:  #cecece;
        color: #000000;
    }
}

.card-button {
    // width: 60px;
    background-color: inherit;
    border: none;
    margin: 2rem 0;
    float: right;
    padding: 0;
    font-size: 13px;
    height: auto;
    color: #D55B0F;
    cursor: pointer;

    img {
        width: 50%;
    }
}

.card-button-link {
  width: 80px;
  background-color: inherit;
  border: none;
  float: right;
  padding: 0;
  height: auto;
  color: #D55B0F;
  // font-size: 12px;
  font-size: 1.33rem;

  img {
      width: 50%;
  }
}

.button__learn-more {
    display: flex;
}

@media (max-width: 768px) {
  .header-btns > a {
    padding-top: 33px;
  }
  .header-btns > button {
    padding-top: 33px;
  }

  .header-btns-browse  {
    // padding-top: 40px;
    padding-bottom: 5px;

  }
}

