.section-faq {
  &__card {
    padding: 2rem 4rem;
    box-shadow: 0 10px 35px rgba(0, 32, 60, 0.05);
    margin-bottom: 3rem;
    
  }

  &__card-nav {
    padding: 2rem 0;
    box-shadow: 0 10px 35px rgba(0, 32, 60, 0.05);
    margin-bottom: 3rem;
  }

  &__box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      cursor: pointer;
      width: 3.5rem;
      height: 3.5rem;
    }
  }

  &__content {
    margin-top: 4rem;
    hr {
      border-top: 0.5px solid rgba(175, 173, 173, 0.3);
      margin-bottom: 3rem;
    }

    p {
      font-size: 1.4rem;
    }
  }

  &__content-nav {
    margin-top: 4rem;
    hr {
      border-top: 0.5px solid rgba(175, 173, 173, 0.3);
      margin-bottom: 3rem;
    }
    p {
      font-size: 1.4rem;
      color: #fff;
      font-weight: bold;
      text-align: center;
    }
  }

  &--title  {
    font: $font-small-bold;
    // align-items: center;
    margin-left: 90px;
  }
  &--title-nav  {
    font: $font-small-bold;
    // margin-left: 37%;
    // text-align: center;
  }
  &--title-prof  {
    font: $font-small-bold;
    // margin-left: 51px;
    // text-align: center;
  }
}