.all-property {
  display: flex;
  justify-content: space-between;
}

.public-details {
  // flex: 0 0 50%
  margin-left: 30px;
  // padding: 5rem;

  width: 57%;
  @include responsive(phone-port) {
    // justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
    width: 90%;
    margin-top: 1rem;
  }
  @include responsive(big-desktop) {
    width: 50%;
  }
}
.public-search {
  // flex: 0 0 50%
  width: 40%;
  @include responsive(phone-port) {
    // justify-content: space-between;
    padding: 2rem;
    width: 100%;
    margin-top: 1rem;
  }
}

.public-top {
  display: flex;
  // align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8rem;
  padding-bottom: 8rem;

  @include responsive(tab-port) {
    flex-direction: column;
    align-items: unset;
    justify-content: center;
  }
  @include responsive(phone-port) {
    flex-direction: column;
    align-items: unset;
    justify-content: center;
  }
  @include responsive(big-desktop) {
    width: 50%;
  }
}

// .slider-slide > img {
//   width: 100%;
//   display: block;
//   height: 200px;
// }

.slider-main {
  width: 100%;
  display: block;
  height: 200px;
}

.slider-single {
  width: 100%;
  display: block;
  height: 400px;
  
  @include responsive(phone-port) {
    height: 200px;
  }
}


.arrow-style {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  padding: 6px;
  top: 10%;
  right: 25px;
  height: 30px;
  width: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #f2f2f2;
  border-radius: 50%;
  cursor: pointer;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: -webkit-transform ease-in 0.1s;
  -webkit-transition: transform ease-in 0.1s;
  transition: transform ease-in 0.1s;
  opacity: 0.5;
}

.arrow-style-single {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  padding: 10px;
  top: 10%;
  right: 25px;
  height: 50px;
  width: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #f2f2f2;
  border-radius: 50%;
  cursor: pointer;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: -webkit-transform ease-in 0.1s;
  -webkit-transition: transform ease-in 0.1s;
  transition: transform ease-in 0.1s;
  opacity: 0.4;

  @include responsive(phone-port) {
    padding: 10px;
    top: 10%;
    right: 25px;
    height: 25px;
    width: 25px;
    opacity: 0.8;
    padding: 5px;
  }
}

.arrow-right {
  margin-top: 90px;
  margin-right: 65px
}

.arrow-left {
  margin-top: -110px;
  margin-right: -45px;
}

.arrow-right-single {
  margin-top: 180px;
  margin-right: 185px;

  @include responsive(phone-port) {
    margin-top: 100px;
    margin-right: 95px;
  }
}

.arrow-left-single {
  margin-top: -220px;
  margin-right: -75px;

  @include responsive(phone-port) {
    margin-top: -100px;
    margin-right: -50px;
  }
}

.location-icon {
  // margin-top: 5px;
  // padding-top: 5px;
  height: 25px;
  width: 20px;
}

.invest-title {
  font: $font-tiny-semi;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.sliderImg {
  height: 133px;
  width: 133px;
  margin-right: 10px;

  &:not(:last-child) {
    margin-right: 10px;
  }
  @include responsive(phone-port) {
    display: none;
  }
}

.sliderImg-single {
  height: 114px;
  width: 114px;
  margin-right: 5px;
  margin-bottom: 30px;

  &:not(:last-child) {
    margin-right: 5px;
  }
  @include responsive(phone-port) {
    display: none;
  }
}

.public-btn {
  margin-left: auto;
}

// .public-images {
//   margin-right: 10px;
// }

.back-button {

  // display: flex;
  margin-left: 100px;

  &-link {
    // width: 80px;
    background-color: inherit;
    border: none;
    // float: right;
    padding: 0;
    height: auto;
    color: #D55B0F;
    // font-size: 12px;
    font-size: 1.33rem;

    img {
        width: 50%;
    }

    &-box {
      margin-top: 4rem;
      display: flex;
      align-items: center;
      border: 1px solid #efefef;
      color: #777777ce;
    }
  }
}


.main-title {
  font: $font-medium;
  font-size: 2.3rem;
  padding: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  flex: 1;
  color: #D55B0F;

  @include responsive(tab-port) {
    font-size: 1.5rem;
  }
}

.featured {
  text-align: center;
  font-size: 20px;
  padding-bottom: 1.5rem;
  color: #D55B0F;

  @include responsive(tab-port) {
    font-size: 1.5rem;
  }
}

.main-amount {
  color: #D55B0F;
}


.property-details {
  margin-top: 2px;

}

.property-details .bg-black {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.property-details .bg-black span {
  display: table-cell;
  font-size: 12px;
  line-height: 38px;
  text-align: center;
  background: #f2f2f2;
  color: #3a3a3a;
  // border-left: 1px solid #3a3a3a;
}

.property-details .property_meta > span + span {
  border-left: 2px solid #fff;
}


.property-details-alt .bg-black {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.property-details-alt .bg-black span {
  display: table-cell;
  font-size: 12px;
  line-height: 38px;
  text-align: center;
  background: #fff;
  color: #3a3a3a;
  // border-left: 1px solid #3a3a3a;
}

.property-details-alt .property_meta > span + span {
  border-left: 2px solid #f2f2f2;
}

.style-logo {
  position: relative;
  top: 6.5px;
  right: 5px;
}

.style-logo-alt {
  position: relative;
  top: 6.5px;
  right: 5px;
}


.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;

  @include responsive(phone-port) {
    display: block;
  }
}
.grid-item {
  padding: 20px 0px;

  font-size: 12px;
  text-align: left;
  border-bottom: 1px dotted;

  @include responsive(phone-port) {
    text-align: left;
  }
}

.box-point {
  position: relative;
  top: 8px;
  width: 10px;
  height: 10px;
  background:#D55B0F;
  float: left;
  margin-right: 8px;

  @include responsive(phone-port) {
    position: relative;
    top: 4.5px;
  }
}

@media (max-width: 768px) {
  .desktop-int {
    display: none;
  }
  .mobile-int { 
    display: block;
  }
}

@media (min-width: 768px) {
  .mobile-int { 
    display: none;
  }
  .desktop-int { 
    display: block;
  }
}




