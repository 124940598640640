.contact {
  background: linear-gradient(to right, rgba(0, 31, 58, 0.8), rgba(0, 31, 58, 0.8)), url("../../images/ContactUs/contact.png");
  background-size: cover;
  min-height: 100vh;
}

.contact-header {
  margin-bottom: 0;
}

.contact-box {
  // top: 80%;
  // left: 40%;
  // transform: translate(-40%, -80%);
  // position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5rem;
  padding-top: 15rem;
  padding-bottom: 10rem;
}

.contact-heading {
  margin-bottom: 2rem;


  &--sub {
    color: #fff;
    font: $font-small-semi;
    margin-bottom: 0;
  }
}

.contact-details {
  max-width: 200px;
  margin-bottom: 2rem;

  p {
    color: rgba(255, 255, 255, 0.644);
    font: $font-tiny;
  }
}

.contact-form {
  background: #fff;
  flex: 0 0 45rem;
  border-radius: 5px;
  padding: 7rem 4rem 3rem 4rem;
  align-self: flex-start;
  margin-left: 20px;
  margin-right: 10px;

  &__heading {
    font: $font-small-bold;
    margin-bottom: 2rem;
  }

  &--input {
    padding: 1rem 1rem;
    font: $font-tiny;
    width: 100%;
    margin-top: 1rem;
    border: none;
    border-bottom: 1px solid #D9D9D9;
    resize: none;
  }

  textarea {
    overflow-y: scroll;
    height: 100px;
  }

  &--btn {
    width: 100%;
    margin: 3rem 0;
  }
}