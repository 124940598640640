// MEDIA QUERIES (DESKTOP FIRST) (DO NOT MODIFY)

/*
    0   -   480px:         phone Portrait   [max-width MQ]

    480px - 992px:        phone Landscape and tablet portrait [max-width MQ]

    992 - 1200px:        Tablet Landscape [max-width MQ]

    1200px - 1800px:    Desktop [first application for default styles]

    1800px + :         Big Desktops     [min-width MQ]

    $breakpoint choices
    - phone
    - tab-port
    - tab-land
    - big-desktop

    1em = 16px

    Example, see _reset.scss under base

*/



@mixin responsive($breakpoint) {
    @if $breakpoint == phone-port {
        @media (max-width: 27em) { @content }; //420px
    }

    @if $breakpoint == tab-port {
        @media (max-width: 48em) { @content }; //768px
    }

    @if $breakpoint == tab-land {
        @media (max-width: 64em) { @content }; //1024px
    }

    @if $breakpoint == big-desktop {
        @media (min-width: 192em) { @content }; //1800px
    }
}