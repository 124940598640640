
.distress-upload > input {
  display: none;
}
.distress-upload-m {
  margin-right: 20px!important;

    @include responsive(phone-port) {
      margin-right: 0px!important;
    }

  // @include responsive(phone-port) {
  //   // justify-content: space-between;
  //   display: block;
  //   width: 100%;
  //   margin-top: 1rem;
  //   // padding-right: 2rem;
  //   // padding-left: -1rem;
  // }
} 