.slider-control-centerleft,
.slider-control-centerright {
    display: none !important;
}

.slider-control-bottomcenter {
    display: flex;
    width: 80%;
    justify-content: flex-end;
    position: relative !important;

    ul {
        width: 10%;
        justify-content: space-between;
    }
}