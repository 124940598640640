.alert {
  width: 90%;
  margin: 2rem auto;
  font: $font-tiny-bold;
  border-radius: 2px;
  color: #000;
  background-color: #fff;
  font-size: 1.2rem;
  padding: 0.5rem;
  text-align: center;
  //   height: 2rem;
  animation-duration: 2s;
  animation-name: moveInBottom;

  &-success {
    border: 1px solid $primary-color;
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}