.card {
  box-shadow: 0 20px 35px rgba(0, 50, 95, 0.1);
  border-radius: 5px;
  transition: 0.3s;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  // min-height: 100vh;
}

.badge-pending {
    display: inline-block;
    width: 60px;
    border-radius: 10%;
    font-size: 5px;
    text-align: center;
    background: #1779ba;
    color: #fefefe;
    font-size: 10px;
    font-weight: bold;
    padding: 0 1rem;

}
.badge-review-success {
  display: inline-block;
  width: 100px;
  border-radius: 10%;
  font-size: 5px;
  text-align: center;
  background: cyan;
  color: #fefefe;
  font-size: 10px;
  font-weight: bold;
  padding: 0 1rem;

}

.badge-review-reject {
  display: inline-block;
  width: 100px;
  border-radius: 10%;
  font-size: 5px;
  text-align: center;
  background: red;
  color: #fefefe;
  font-size: 10px;
  font-weight: bold;
  padding: 0 1rem;

}

.badge-search-success {
  display: inline-block;
  width: 100px;
  border-radius: 10%;
  font-size: 5px;
  text-align: center;
  background: rgb(190, 202, 76);
  color: #fefefe;
  font-size: 10px;
  font-weight: bold;
  padding: 0 1rem;
}

.badge-search-reject {
  display: inline-block;
  width: 100px;
  border-radius: 10%;
  font-size: 5px;
  text-align: center;
  background: red;
  color: #fefefe;
  font-size: 10px;
  font-weight: bold;
  padding: 0 1rem;
}

.badge-structuring {
  display: inline-block;
  width: 100px;
  border-radius: 10%;
  font-size: 5px;
  text-align: center;
  background: #2d8be2;
  color: #fefefe;
  font-size: 10px;
  font-weight: bold;
  padding: 0 1rem;
}

.badge-public {
  display: inline-block;
  width: 90px;
  border-radius: 10%;
  font-size: 5px;
  text-align: center;
  background: #2d8be2;
  color: #fefefe;
  font-size: 10px;
  font-weight: bold;
  padding: 0 1rem;
}


.badge-diligence {
  display: inline-block;
  width: 120px;
  border-radius: 10%;
  font-size: 5px;
  text-align: center;
  background: rgb(190, 202, 76);
  color: #fefefe;
  font-size: 10px;
  font-weight: bold;
  padding: 0 1rem;
}

.badge-contribution-received {
  display: inline-block;
  width: 140px;
  border-radius: 10%;
  font-size: 5px;
  text-align: center;
  background: #0994be;
  color: #fefefe;
  font-size: 10px;
  font-weight: bold;
  padding: 0 1rem;
}

.badge-document-received {
  display: inline-block;
  width: 130px;
  border-radius: 10%;
  font-size: 5px;
  text-align: center;
  background: rgb(69, 124, 69);
  color: #fefefe;
  font-size: 10px;
  font-weight: bold;
  padding: 0 1rem;
}

.badge-monthly-rent {
  display: inline-block;
  width: 120px;
  border-radius: 10%;
  font-size: 5px;
  text-align: center;
  background: #21c02e;
  color: #fefefe;
  font-size: 10px;
  font-weight: bold;
  padding: 0 1rem;
}


.paid-month {
  display: inline-block;
  width: 60px;
  border-radius: 10%;
  font-size: 5px;
  text-align: center;
  background: #21c02e;
  color: #fefefe;
  font-size: 10px;
  font-weight: bold;
  padding: 0 1rem;
}


.badge-complete-rent {
  display: inline-block;
  width: 120px;
  border-radius: 10%;
  font-size: 5px;
  text-align: center;
  background: #228B22;
  color: #fefefe;
  font-size: 10px;
  font-weight: bold;
  padding: 0 1rem;
}


.profile-card {
  width: 30%;
  padding: 2rem;
  border-radius: 5px;
  background-color: #fff;

  @include responsive(tab-port) {
      width: 40%;
  }

  @include responsive(phone-port) {
    width: 95%;
    padding-left: 20px;
    padding-right: 20px;
  }

  &__image {
    width: 100%;
    height: auto;

    img {
      width: 100%;
      height: 200px;
      border-radius: 5px;
    }
  }

  &__header {
    color: #de6c31;
    font: $font-small-semi;
    margin: 1rem 0;
    text-align: center;
    text-transform: capitalize;
  }

  &__description {
    font: $font-tiny;
    text-align: center;
    height: 8.8rem;
    overflow: hidden;
  }

  &:not(:last-child) {
    margin-right: 20px;
    min-width: 8.5rem;
  }

  &__funds {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font: $font-tiny-semi;
    }

    h2 {
      font: $font-small;
      color: #5c5c5c;
    }
  }

  &-returns {
    margin-top: -10px;
  }

  &__bottom {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &-raised {
    // flex: 1;
    // width: 30%;
    text-align: center;

    &:not(:last-child) {
      margin-right: 5px;
    }

    h3 {
      font: $font-tiny-semi;
      font-size: 1rem;
      text-transform: uppercase;
    }

    h2 {
      font: $font-small;
      color: #5c5c5c;
    }

    &::last-child {
      flex: 1;
    }
  }
  margin-bottom: 20px;

  &__button {
    float: right;
    margin: 2rem 0;
    background-color: #fff;
    border: 1px solid $primary-color;
    border-radius: 100px;
    padding: 0.7rem;
    width: 10rem;
    margin-right: 0.5rem;
  }
}

.mobile-card {

  margin-left: 5px;
  margin-top: 10px;
  width: 100%;
  background-color: #ecf0f7;
  padding: 2rem 2rem 6rem 2rem;
  border-radius: 5px;

  &__image {
    width: 100%;
    height: auto;

    img {
      width: 100%;
      height: 200px;
      border-radius: 5px;
    }
  }

  &__header {
    color: #de6c31;
    font: $font-small-semi;
    margin: 1rem 0;
    text-align: center;
    text-transform: capitalize;
  }

  &__description {
    font: $font-tiny;
    text-align: center;
    height: 8.8rem;
    overflow: hidden;
  }

  &:not(:last-child) {
    margin-right: 20px;
    min-width: 8.5rem;
  }

  &__funds {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font: $font-tiny-semi;
    }

    h2 {
      font: $font-small;
      color: #5c5c5c;
    }
  }

  &-returns {
    margin-top: -10px;
  }

  &__bottom {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  margin-bottom: 20px;
}


.card-public {
  width: 100%;
  padding: 1rem;
  border-radius: 5px;
  background-color: #ecf0f7;
  margin-left: 20px;

  @include responsive(tab-port) {
      width: 40%;
  }

  @include responsive(phone-port) {
    width: 95%;
    padding-left: 20px;
    padding-right: 20px;
  }

  &__image {
    width: 100%;
    height: auto;

    // img {
    //   width: 100%;
    //   height: 200px;
    //   border-radius: 5px;
    // }
  }

  &__header {
    color: #de6c31;
    font: $font-small-semi;
    margin: 1rem 0;
    text-align: center;
    text-transform: capitalize;
  }

  &__description {
    font: $font-tiny;
    text-align: center;
    height: 8.8rem;
    overflow: hidden;
  }

  &__funds {
    margin-top: 1rem;
    display: flex;
    justify-content: left;

    h3 {
      font: $font-tiny-semi;
    }

    h2 {
      font: $font-small;
      color: #5c5c5c;
    }
  }

  &-returns {
    width: 35%;
    margin-top: -10px;
  }
  &-word {
    width: 65%;
    margin-left: 20px;
    margin-top: -15px;
  }

  &__bottom {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &-raised {
    text-align: center;

    &:not(:last-child) {
      margin-right: 5px;
    }

    h3 {
      font: $font-tiny-semi;
      font-size: 1rem;
      text-transform: uppercase;
    }

    h2 {
      font: $font-small;
      color: #5c5c5c;
    }

    &::last-child {
      flex: 1;
    }
  }
  margin-bottom: 20px;

  &__button {
    float: right;
    margin: 2rem 0;
    background-color: #fff;
    border: 1px solid $primary-color;
    border-radius: 100px;
    padding: 0.7rem;
    width: 10rem;
    margin-right: 0.5rem;
  }
}

.filter-public {
  width: 100%;
  padding: 1rem;
  border-radius: 5px;
  background-color: #f2f2f2;
  margin-left: 20px;

  @include responsive(tab-port) {
      width: 40%;
  }

  @include responsive(phone-port) {
    width: 95%;
    padding-left: 20px;
    padding-right: 20px;
  }

  // &__image {
  //   width: 96%;
  //   height: auto;

  //   img {
  //     width: 100%;
  //     height: 200px;
  //     border-radius: 5px;
  //   }
  // }

  &__header {
    color: #de6c31;
    font: $font-small-semi;
    margin: 1rem 0;
    text-align: center;
    text-transform: capitalize;
  }

  &__description {
    font: $font-tiny;
    text-align: center;
    height: 8.8rem;
    overflow: hidden;
  }

  &:not(:last-child) {
    margin-right: 20px;
    min-width: 8.5rem;
  }

  // &__funds {
  //   margin-top: 1rem;
  //   display: flex;
  //   // align-items: center;
  //   justify-content: left;

  //   h3 {
  //     font: $font-tiny-semi;
  //   }

  //   h2 {
  //     font: $font-small;
  //     color: #5c5c5c;
  //   }
  // }

  &-returns {
    margin-top: -10px;
  }

  &__bottom {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &-raised {
    // flex: 1;
    // width: 30%;
    text-align: center;

    &:not(:last-child) {
      margin-right: 5px;
    }

    h3 {
      font: $font-tiny-semi;
      font-size: 1rem;
      text-transform: uppercase;
    }

    h2 {
      font: $font-small;
      color: #5c5c5c;
    }

    &::last-child {
      flex: 1;
    }
  }
  margin-bottom: 20px;

  &__button {
    float: right;
    margin: 2rem 0;
    background-color: #fff;
    border: 1px solid $primary-color;
    border-radius: 100px;
    padding: 0.7rem;
    width: 10rem;
    margin-right: 0.5rem;
  }
}



.profile-card-btn {
  width: 30%;
  padding: 2rem;
  border-radius: 5px;
  // background-color: #fff;
  margin-bottom: 20px;
}

.investment-card-wrap {
  width: 31%;
  padding: 2rem 2rem;
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 3rem;

  &:not(:last-child) {
    margin-right: 3rem;
  }
}

.investment-card {
  width: 100%;
  &--header {
    text-align: center;
    margin-bottom: 2rem;

    h1 {
      font: $font-medium-semi;
      color: $primary-color;
    }

    h2 {
      font: $font-small;
      font-size: 1.4rem;
      margin-top: 1rem;
      height: 10rem;
      overflow: hidden;
    }
  }
}

.investment-card-box {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  border: 1px solid #efefef;
  color: #777777ce;


  // h1,h2 {
  //     font: $font-small;
  //     font-size: 1.4rem;
  // }
}

.investment-card--title {
  font: $font-small-bold;
  font-size: 1.3rem;
  text-transform: capitalize;
  width: 40%;
  border-right: 1px solid #efefef;
  padding: 0.5rem;

  @include responsive(tab-port) {
    font-size: 1.5rem;
  }
}

.investment-card--public {
  font: $font-small-bold;
  font-size: 1.3rem;
  text-transform: capitalize;
  width: 40%;
  border-right: 1px solid #efefef;
  // padding: 0.5rem;
  padding-bottom: -10px;

  @include responsive(tab-port) {
    font-size: 1.5rem;
  }
}

.investment-card--sub {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.investment-card--progress {
  width: 120px;
  height: 7px;
  margin-left: 10px;
  border-radius: 50px;
  background-color: #ecf0f7;
}

.investment-card--value {
  font: $font-small;
  font-size: 1.3rem;
  padding: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  flex: 1;

  @include responsive(tab-port) {
    font-size: 1.5rem;
  }
}


.asset-card {
  width: 30%;
  padding: 2rem;
  border-radius: 5px;
  background-color: #fff;

  @include responsive(tab-port) {
      width: 40%;
  }

  @include responsive(phone-port) {
    width: 100%;
    height: 200px;
  }
  @include responsive(big-desktop) {
    width: 100%;
  }

  &__image {
    width: 100%;
    height: auto;

    img {
      width: 100%;
      height: 200px;
      border-radius: 5px;

      // @include responsive(desktop) {
      //   width: 100%;
      //   height: auto;
      // }
    }
  }

  &__header {
    color: #de6c31;
    font: $font-small-semi;
    margin: 1rem 0;
    text-align: center;
    text-transform: capitalize;
  }

  &__header_public {
    color: #000;
    font: $font-small;
    margin: 1rem 0;
    text-align: center;
    text-transform: capitalize;
  }

  &__description {
    font: $font-tiny;
    text-align: center;
    height: 8.8rem;
    overflow: hidden;
  }

  &:not(:last-child) {
    margin-right: 20px;
    min-width: 8.5rem;
  }

  &__funds {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font: $font-tiny-semi;
    }

    h2 {
      font: $font-small;
      color: #5c5c5c;
    }
  }

  &-returns {
    margin-top: -10px;
  }

  &__bottom {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &-raised {
    // flex: 1;
    // width: 30%;
    text-align: center;

    &:not(:last-child) {
      margin-right: 5px;
    }

    h3 {
      font: $font-tiny-semi;
      font-size: 1rem;
      text-transform: uppercase;
    }

    h2 {
      font: $font-small;
      color: #5c5c5c;
    }

    &::last-child {
      flex: 1;
    }
  }
  margin-bottom: 20px;

  &__button {
    float: right;
    margin: 2rem 0;
    background-color: #fff;
    border: 1px solid $primary-color;
    border-radius: 100px;
    padding: 0.7rem;
    width: 10rem;
    margin-right: 0.5rem;
  }
}