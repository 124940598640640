.nav-dot {
  display: flex;
  justify-content: center;
}

.nav-dot-box {
  display: flex;
  align-items: center;
}

.dot {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  background-color: #ededed;
  transition: all .3s;

  @include responsive(phone-port) {
    width: 10px;
    height: 10px;
  }
}

.dot-line {
  border: 2px solid #ededed;
  width: 150px;
  transition: all .3s;

   @include responsive(phone-port) {
    width: 50px;
  }
}

.nav-active {
    .dot {
      background-color: #d55b0f;
    }

    .dot-title {
      background-color: #d55b0f;
    }

    .dot-line {
      border-color: #d55b0f;
    }
}

.dot-title {
  width: 25px;
  height: 25px;
  border-radius: 100px;
  background-color: #ededed;
  color: white;
  transition: all .3s;
  display: flex;
  justify-content: center;
}