.noti-card {
  width: 100%;
  padding: 2rem;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  
  &-arrow {
    cursor: pointer;
  }
  // &__unread {
  //   background-color: #000000;
  //   color: red;
  //   // display: none;
  // }

  @include responsive(tab-port) {
      width: 40%;
  }

  @include responsive(phone-port) {
    width: 100%;
    height: 200px;
  }
  @include responsive(big-desktop) {
    width: 100%;
  }

  &__header {
    color: #de6c31;
    font: $font-small-semi;
    margin: 1rem 0;
    text-align: center;
    text-transform: capitalize;
  }

  &__description {
    font: $font-tiny;
    text-align: center;
    height: 8.8rem;
    overflow: hidden;
  }
  margin-right: 20px;
  min-width: 8.5rem;

  &__funds {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font: $font-tiny-semi;
    }

    h2 {
      font: $font-small;
      color: #5c5c5c;
    }
  }

  &-returns {
    margin-top: -10px;
  }

  &__bottom {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  margin-bottom: 20px;

}


.noti-details-unread {
  width: 98%;
  padding: 2rem;
  border-radius: 5px;
  background-color: rgb(218, 206, 206);
  margin-right: 20px;
  min-width: 8.5rem;
  margin-bottom: 20px;

  @include responsive(tab-port) {
    width: 40%;
  }

  @include responsive(phone-port) {
    width: 100%;
    height: 140px;
  }
  @include responsive(big-desktop) {
    width: 100%;
  }

  &__header {
    color: #de6c31;
    font: $font-small-semi;
    margin: 1rem 0;
    text-transform: capitalize;
  }
}

.noti-details {
  width: 98%;
  padding: 2rem;
  border-radius: 5px;
  background-color: #fff;
  margin-right: 20px;
  min-width: 8.5rem;
  margin-bottom: 20px;

  @include responsive(tab-port) {
    width: 40%;
  }

  @include responsive(phone-port) {
    width: 100%;
    height: 140px;
  }
  @include responsive(big-desktop) {
    width: 100%;
  }

  &__header {
    color: #de6c31;
    font: $font-small-semi;
    margin: 1rem 0;
    text-transform: capitalize;
  }

  &__description {
    font: $font-tiny;
    text-align: center;
    height: 8.8rem;
    overflow: hidden;
  }
}

.noti-button {
  background-color: #DF6F12; 
  border: none; 
  color: white; padding: 10px 10px; 
  text-decoration: none; 
  text-transform: uppercase; 
  display: inline-block; 
  font-weight: 600; 
  border-radius: 3px; 
  margin: 10px 0px; 
  cursor: pointer;
}

.noti-button-disabled {
  background-color: #cecece; 
  border: none; 
  color: white; padding: 10px 10px; 
  text-decoration: none; 
  text-transform: uppercase; 
  display: inline-block; 
  font-weight: 600; 
  border-radius: 3px; 
  margin: 10px 0px; 
  cursor: not-allowed;
}