.section-file {
  &__card {
    padding: 2rem 4rem;
    box-shadow: 0 10px 35px rgba(0, 32, 60, 0.05);
    margin-bottom: 3rem;
    background-color: #be3131;
  }

  &__box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      cursor: pointer;
      width: 1.5rem;
    }
  }

  &__content {
    margin-top: 4rem;
    hr {
      border-top: 0.5px solid rgba(175, 173, 173, 0.3);
      margin-bottom: 3rem;
    }

    p {
      font-size: 1.4rem;
    }
  }

  &--title  {
    font: $font-small-bold;
  }
}