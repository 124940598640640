.link-input {
  // width: 120%;
  /* padding: 1.5rem 1.4rem; */
  border: none;
  /* font: normal 500 1.2rem/2.6rem "Graphik"; */
  font-size: 1.33rem; 
  outline: none;
  color: #00325f;
  /* border: 2px solid transparent; */
  display: block;
  border-radius: 5px;
  transition: all 0.3s;
}

.dsr-public-box {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dsr-public-body {

  margin-bottom: 40px; 

  @include responsive(phone-port) {
    margin-bottom: 20px; 
  }
}